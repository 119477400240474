import React, { ChangeEvent } from "react";
import { FieldError } from "react-hook-form";

interface TextfieldProps {
  id?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  className?: string;
  style?: React.CSSProperties;
  register?: any;
  error?: FieldError;
  label?: string;
  disabled?: boolean;
}

const Textfield: React.FC<TextfieldProps> = ({
  id,
  type = "text",
  placeholder = "",
  value,
  onChange,
  required = false,
  className = "",
  style,
  register,
  error,
  label,
  disabled = false,
}) => {
  return (
    <div className={`${className} flex flex-col relative`}>
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}

      <input
        disabled={disabled}
        style={style}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`w-full h-[50px] p-3 border rounded-md bg-white text-gray-900 placeholder-gray-200  ${
          error
            ? "border-red-500 bg-[var(--Danger-Danger-Light,#FFF1F2)]"
            : "border-gray-400"
        }`}
        required={required}
        {...(register && register(id))}
      />

      {error && (
        <p className="mt-2 text-red-500 text-body-reg-12 font-medium">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default Textfield;
