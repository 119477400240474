import React from "react";
import CustomButton from "@components/core-components/button/CustomButton";
import WithdrawAmountButton from "./WithdrawAmountButton";

interface WithdrawFormProps {
  title: string;
  selectedAmount: number;
  setSelectedAmount: (amount: number) => void;
  amounts: number[];
}

const WithdrawForm: React.FC<WithdrawFormProps> = ({
  title,
  selectedAmount,
  setSelectedAmount,
  amounts,
}) => (
  <div className="max-w-[460px]">
    <div className="text-white font-semibold text-base mb-2">{title}</div>
    <p className="text-sm text-gray-200 mb-4">
      You can withdraw between €200 and €100,000.
    </p>
    <div className="flex gap-1 mb-5">
      {amounts.map((amount) => (
        <WithdrawAmountButton
          key={amount}
          amount={amount}
          selectedAmount={selectedAmount}
          onClick={setSelectedAmount}
        />
      ))}
    </div>
    <input
      type="number"
      value={selectedAmount}
      onChange={(e) => setSelectedAmount(Number(e.target.value))}
      className="w-full p-3 rounded-lg text-gray-900 placeholder-gray-900 mb-5"
    />
    <CustomButton
      text="Withdraw"
      onClick={() => console.log("Withdraw action")}
      buttonType="primary"
    />
  </div>
);

export default WithdrawForm;
