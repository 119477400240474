import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../store/auth/authenticationSlice";
import casinoReducer from '../store/casino/casinoSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  casino: casinoReducer
});

export default rootReducer;
