import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomButton from "@components/core-components/button/CustomButton";
import Textfield from "@components/core-components/input/Textfield";
import Icon from "@components/core-components/icon";

interface ForgotPasswordFormProps {
  onBack: () => void;
}

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onBack }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    console.log("Forgot Password Email:", data.email);
    reset();
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-2">
        <button type="button" onClick={onBack} className="text-white">
          <Icon iconName="backSquare" svgProps={{ width: 20, height: 20 }} />
        </button>
        <h2 className="text-white font-semibold text-lg">Forgot Password</h2>
      </div>
      <Textfield
        type="email"
        placeholder="Email*"
        register={register}
        id="email"
        error={errors.email}
      />
      <CustomButton text="SEND" type="submit" />
    </form>
  );
};

export default ForgotPasswordForm;
