import MenuList from "@components/core-components/MenuList";
import GameCard from "@components/core-components/card/GameCard";
import CustomSwiper from "@components/core-components/swiper";
import React, { useEffect, useState } from "react";
import SearchBar from "@components/core-components/input/SearchBar";
import { casinoGames, fetchGames } from "store/casino/casinoSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";

function Menu() {
  const dispatch = useAppDispatch();
  const games = useAppSelector(casinoGames);
  const [activeCategory, setActiveCategory] = useState("");
  const [isSearchModalVisible, setSearchModalVisible] =
    useState<boolean>(false);

  const handleOpenSearchModal = () =>
    setSearchModalVisible(!isSearchModalVisible);

  useEffect(() => {
    dispatch(fetchGames());
  }, []);

  const breakpoints = {
    "0": { slidesPerView: 3, spaceBetween: 0 }, // Mobile screens
    "820": { slidesPerView: 3.5, spaceBetween: 0 },
    "950": { slidesPerView: 2, spaceBetween: 0 },
    "1024": { slidesPerView: 2.5, spaceBetween: 0 },
    "1190": { slidesPerView: 3, spaceBetween: 0 },
    "1200": { slidesPerView: 3.5, spaceBetween: 0 },
    "1260": { slidesPerView: 4.3, spaceBetween: 0 },
    "1550": { slidesPerView: 6, spaceBetween: 0 },
    "1800": { slidesPerView: 7.5, spaceBetween: 0 },
    "2000": { slidesPerView: 9, spaceBetween: 0 },
  };

  return (
    <div className="w-full h-full pt-6 pb-72 overflow-x-hidden main-no-scrollbar">
      <div className="flex w-full items-center justify-between px-3">
        <SearchBar onClick={handleOpenSearchModal} value="" />
      </div>
      <div className="pt-6 w-full px-3 md:px-0 pb-6 border-b border-gray-300">
        <CustomSwiper
          title="LAST PLAYS"
          items={games}
          breakpoints={breakpoints}
          renderItem={(game) => (
            <GameCard
              image={game.url_thumb}
              id={game.game_id}
              name={game.game_name}
              altText={game.game_name}
              notAvailable={game.status === "ACTIVE" ? false : true}
              showPlayCount={true}
            />
          )}
        />
      </div>
      <h2 className="text-base font-black mb-4 text-white px-3 pt-6">MENU</h2>
      <MenuList
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
    </div>
  );
}

export default Menu;
