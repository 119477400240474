import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAppDispatch } from "../../hooks/redux";
import { authenticationLogin } from "store/auth/authenticationSlice";
import CustomButton from "@components/core-components/button/CustomButton";
import Textfield from "@components/core-components/input/Textfield";

interface LoginFormProps {
  onForgotPassword: () => void;
  onClose: () => void;
}

const loginSchema = yup.object().shape({
  emailLogin: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  passwordLogin: yup.string().required("Password is required"),
});

const LoginForm: React.FC<LoginFormProps> = ({ onForgotPassword, onClose }) => {
  const dispatch = useAppDispatch();
  const [loginError, setLoginError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      await dispatch(
        authenticationLogin({
          email: data.emailLogin,
          password: data.passwordLogin,
        })
      ).unwrap();
      onClose();
    } catch (error) {
      console.log("error", error);

      setLoginError(error as string);
    }
  };

  const onError = (errors: any) => {
    setLoginError("Login failed.");
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit, onError)}>
      <Textfield
        type="email"
        placeholder="Email*"
        register={register}
        id="emailLogin"
        error={errors.emailLogin}
      />
      <Textfield
        type="password"
        placeholder="Password*"
        register={register}
        id="passwordLogin"
        error={errors.passwordLogin}
      />
      {loginError && <p className="text-red-500">{loginError}</p>}
      <div className="flex justify-end">
        <button
          type="button"
          className="text-sm text-white font-semibold"
          onClick={onForgotPassword}
        >
          Forgot Password
        </button>
      </div>
      <div className="flex justify-between items-center">
        <CustomButton text="SIGN IN" type="submit" />
      </div>
    </form>
  );
};

export default LoginForm;
