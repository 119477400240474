import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "@components/core-components/icon";

interface PlatformModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PlatformModal: React.FC<PlatformModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const platformOptions = [
    { path: "/", label: "SPORTSBOOK", iconName: "sportsbook" },
    { path: "/", label: "CASINO", iconName: "casino" },
    { path: "/", label: "EXCHANGE", iconName: "exchange" },
  ];

  if (!isOpen) return null;

  return (
    <>
      <div
        id="modal-background"
        className="fixed inset-0 bg-black bg-opacity-50 z-30"
        style={{ bottom: "100px" }}
        onClick={(e) => {
          if ((e.target as HTMLElement).id === "modal-background") onClose();
        }}
      />
      <div className="fixed inset-x-0 bottom-[100px] bg-gray-600 rounded-[10px] px-3 py-7 w-3/4 mx-auto mb-[10px] flex justify-between z-40">
        {platformOptions.map((option) => (
          <button
            key={option.path}
            className="flex flex-col items-center text-white"
            onClick={() => {
              navigate(option.path);
              onClose();
            }}
          >
            <Icon
              iconName={option.iconName}
              svgProps={{ width: 40, height: 40 }}
            />
            <span className="mt-2 font-black text-xs">{option.label}</span>
          </button>
        ))}
      </div>
    </>
  );
};

export default PlatformModal;
