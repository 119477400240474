import CategoryList from "@components/core-components/CategoryList";
import MenuList from "@components/core-components/MenuList";
import React, { useState } from "react";

function LeftMenu() {
  const [activeTab, setActiveTab] = useState("Casino");
  const [activeCategory, setActiveCategory] = useState("Lobby");

  return (
    <aside className="bg-gray-600 w-[190px] min-w-[190px] pb-96 space-y-4 h-screen overflow-x-hidden main-no-scrollbar">
      <div className="flex flex-col space-y-2 p-4 border-b border-gray-300">
        {["Casino", "Sportbook", "Exchange"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`h-[42px] text-left font-semibold text-sm px-4 rounded-lg transition-colors duration-200 ${
              activeTab === tab
                ? "bg-gradient-to-r from-[#D410D9] to-[#180729] text-white border border-[#F20AFF]"
                : "bg-gray-700 text-white border border-gray-400 hover:bg-gray-400"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      <CategoryList
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
      <MenuList
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
    </aside>
  );
}

export default LeftMenu;
