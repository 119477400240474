import axiosInstance from "config/axiosInstance";

const authService = {
  login: async (email: string, password: string) => {
    try {
      const data = {
        email: email,
        password: password,
      };
      const response = await axiosInstance.post("auth/login", data, {});
      console.log("response", response);

      return response;
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  },
  confirm: async (email: string, code: string) => {
    try {
      const data = {
        email: email,
        confirmationCode: code,
      };

      const response = await axiosInstance.post("auth/confirm", data);

      return response;
    } catch (error) {
      console.error("Error during confirm:", error);
      throw error;
    }
  },
  register: async (email: string, password: string, username: string) => {
    try {
      const data = {
        email: email,
        password: password,
        username: username,
      };

      const response = await axiosInstance.post("auth/register", data);

      return response;
    } catch (error) {
      console.error("Error during register:", error);
      throw error;
    }
  },
  checkAuthStatus: async () => {
    const response = await axiosInstance.get("auth/check");

    return response.data;
  },

  logout: async () => {
    try {
      await axiosInstance.get("auth/logout"); // Assume your backend removes the cookie
      return true;
    } catch (error) {
      console.error("Error during logout:", error);
      throw error;
    }
  },
};

export default authService;
