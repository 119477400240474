import React from "react";
import Icon from "./icon";

interface FavoriteGameCardsProps {
  game: {
    id: number;
    name: string;
    image: string;
  };
  handleNavigate: (id: number, name: string) => void;
  isFavorite: boolean;
  toggleFavorite: (id: number) => void;
  isFavoriteIconShown?: boolean;
}

const FavoriteGameCards: React.FC<FavoriteGameCardsProps> = ({
  game,
  handleNavigate,
  isFavorite,
  toggleFavorite,
  isFavoriteIconShown = false,
}) => {
  return (
    <button
      onClick={() => handleNavigate(game.id, game.name)}
      className="flex items-center justify-between w-full h-[92px] bg-gray-400 text-white p-2 rounded-lg transition-transform duration-200 hover:scale-105"
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="w-[57px] h-[76px] rounded-lg">
            <img
              loading="lazy"
              src={game.image}
              alt={game.name}
              className="w-[57px] h-[76px] object-cover rounded-lg"
            />
          </div>
          <div className="text-left">
            <h3 className="text-base font-semibold leading-none">
              {game.name}
            </h3>
            <span className="text-xs text-gray-200 leading-none">
              {"Pragmatic"}
            </span>
          </div>
        </div>
        {isFavoriteIconShown && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleFavorite(game.id);
            }}
          >
            <Icon
              iconName="favoriteHeart"
              svgProps={{
                width: 26,
                height: 26,
                fill: isFavorite ? "#FFD700" : "#FFFFFF",
              }}
            />
          </div>
        )}
      </div>
    </button>
  );
};

export default FavoriteGameCards;
