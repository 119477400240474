import React from "react";

interface WithdrawAmountButtonProps {
  amount: number;
  selectedAmount: number;
  onClick: (amount: number) => void;
}

const WithdrawAmountButton: React.FC<WithdrawAmountButtonProps> = ({
  amount,
  selectedAmount,
  onClick,
}) => (
  <button
    onClick={() => onClick(amount)}
    className={`px-4 py-2 rounded-lg text-xs font-semibold ${
      selectedAmount === amount
        ? "bg-yellow text-black"
        : "bg-gray-700 text-white"
    }`}
  >
    €{amount.toLocaleString()}
  </button>
);

export default WithdrawAmountButton;
