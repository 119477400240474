import React from "react";
import cx from "classnames";
import players from "@icons/players.svg";
import usersOcta from "@icons/user-octagon.svg";
import house from "@icons/house.svg";
import ReportIcon from "@icons/report.svg";
import payment from "@icons/payment.svg";
import promotion from "@icons/promotion.svg";
import bookOpen from "@icons/book-open.svg";
import categoryIcon2 from "@icons/category-2.svg";
import userPen from "@icons/user-pen.svg";
import rightArrow from "@icons/right-arrow.svg";
import leftArrow from "@icons/left-arrow.svg";
import calendar from "@icons/calendar.svg";
import close from "@icons/Popup-Close.svg";
import currency from "@icons/currencytree.svg";
import download from "@icons/download.svg";
import edit from "@icons/edit.svg";
import profile from "@icons/profile.svg";
import verified from "@icons/verified.svg";
import star from "@icons/star.svg";
import checkedstar from "@icons/checked-star.svg";
import filter from "@icons/filter.svg";
import cancel from "@icons/cancel.svg";
import twoChoice from "@icons/two-choice.svg";
import plus from "@icons/plus-sign.svg";
import minus from "@icons/minus-sign.svg";
import search from "@icons/search.svg";
import logo from "@icons/pixup-icon.svg";
import fileupload from "@icons/fileupload.svg";
import purpleArrow from "@icons/purple-arrow.svg";
import whiteArrow from "@icons/white-arrow.svg";
import closeCircle from "@icons/close-circle.svg";
import trash from "@icons/close-circle.svg";
import trashCan from "@icons/trash.svg";
import selected from "@icons/selected.svg";
import downArrow from "@icons/down-arrow.svg";
import whiteCancel from "@icons/cancel-white.svg";
import bonus from "@icons/bonus.svg";
import backIcon from "@icons/back-Icon.svg";
import checkbox from "@icons/Checkbox.svg";
import unCheckedbox from "@icons/uncheckbox.svg";
import indigoUpArrow from "@icons/indigo-up-arrow.svg";
import indigoDownArrow from "@icons/indigo-down-arrow.svg";
import move from "@icons/move.svg";
import ellipse10 from "@icons/ellipse10.svg";
import ellipse3 from "@icons/ellipse3.svg";
import UK from "@icons/united-kingdom.svg";
import indianRupee from "@icons/indianruppiee.svg";
import profileSite from "@icons/profile-site.svg";
import notification from "@icons/notification.svg";
import usdt from "@icons/usdt.svg";
import blueCheckbox from "@icons/BlueCheckbox.svg";
import searchWhite from "@icons/search-white.svg";
import avatarCircle from "@icons/avatar-circle.svg";
import ellipse20 from "@icons/ellipse20.svg";
import carouselRightIcon from "@icons/slider-arrow-right.svg";
import favorite from "@icons/favorite.svg";
import fullscreen from "@icons/fullscreen.svg";
import closeIcon from "@icons/close-icon.svg";
import pixupYellowLogo from "@icons/pixup-yellow-logo.svg";
import pixupClientText from "@icons/pixup-client-text.svg";
import facebook from "@icons/facebook.svg";
import usdt2 from "@icons/usdt2.svg";
import ghost from "@icons/ghost.svg";
import sliderArrowRight from "@icons/slider-arrow-right.svg";
import sliderArrowLeft from "@icons/slider-arrow-left.svg";
import support from "@icons/support.svg";
import region from "@icons/region.svg";
import check from "@icons/check.svg";
import checkSuccess from "@icons/check-success.svg";
import backSquare from "@icons/back-square.svg";
import gradientBgLogo from "@icons/gradient-background-logo.svg";
import filterBy from "@icons/filter-by.svg";
import sortBy from "@icons/sort-by.svg";
import affiliate from "@icons/affiliate.svg";
import blog from "@icons/blog.svg";
import forum from "@icons/forum.svg";
import gameShows from "@icons/game-shows.svg";
import crashGames from "@icons/crash-games.svg";
import liveCasino from "@icons/live-casino.svg";
import lobby from "@icons/lobby.svg";
import promotionIcon from "@icons/promotion-icon.svg";
import release from "@icons/release.svg";
import responsibleGambling from "@icons/responsible-gambling.svg";
import slots from "@icons/slots.svg";
import sponsorship from "@icons/sponsorship.svg";
import vipClub from "@icons/vip-club.svg";
import profileIcon from "@icons/profile-icon.svg";
import profileIconWhite from "@icons/profile-icon-white.svg";
import myBets from "@icons/my-bets.svg";
import settings from "@icons/settings.svg";
import logout from "@icons/logout.svg";
import pixupCasino from "@icons/pixup-casino.svg";
import pixupClient from "@icons/pixup-client.svg";
import pixupPink from "@icons/pixup-pink.svg";
import pixupYellow from "@icons/pixup-yellow.svg";
import categories from "@icons/categories.svg";
import chat from "@icons/chat.svg";
import favorites from "@icons/favorites.svg";
import platform from "@icons/platform.svg";
import searchMenu from "@icons/search-menu.svg";
import casino from "@icons/casino.svg";
import exchange from "@icons/exchange.svg";
import sportsbook from "@icons/sportsbook.svg";
import favoriteHeart from "@icons/favorite-heart.svg";
import onlinedot from "@icons/onlinedot.svg";
import tether from "@icons/tether.svg";
import withdrawableAmount from "@icons/withdrawable-amount.svg";
import bonusDependent from "@icons/bonus-dep.svg";
import bonusIcon from "@icons/bonus-icon.svg";

type IconProps = {
  iconName: string;
  className?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
  iconclass?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

type IconMap = {
  [key: string]: string;
};

const icons: IconMap = {
  indianRupee,
  players,
  calendar,
  whiteCancel,
  usersOcta,
  star,
  checkedstar,
  unCheckedbox,
  checkbox,
  whiteArrow,
  edit,
  purpleArrow,
  indigoUpArrow,
  indigoDownArrow,
  verified,
  cancel,
  filter,
  blueCheckbox,
  download,
  bonus,
  profile,
  house,
  report: ReportIcon,
  close,
  currency,
  plus,
  minus,
  payment,
  promotion,
  bookOpen,
  settings,
  twoChoice,
  categoryIcon2,
  userPen,
  rightArrow,
  leftArrow,
  search,
  logo,
  fileupload,
  closeCircle,
  trash,
  trashCan,
  selected,
  downArrow,
  backIcon,
  move,
  ellipse10,
  ellipse3,
  UK,
  profileSite,
  notification,
  usdt,
  searchWhite,
  avatarCircle,
  ellipse20,
  carouselRightIcon,
  favorite,
  fullscreen,
  closeIcon,
  pixupYellowLogo,
  pixupClientText,
  facebook,
  usdt2,
  ghost,
  sliderArrowRight,
  sliderArrowLeft,
  support,
  region,
  check,
  checkSuccess,
  backSquare,
  gradientBgLogo,
  filterBy,
  sortBy,
  affiliate,
  blog,
  forum,
  gameShows,
  crashGames,
  liveCasino,
  lobby,
  promotionIcon,
  release,
  responsibleGambling,
  slots,
  sponsorship,
  vipClub,
  profileIcon,
  profileIconWhite,
  myBets,
  logout,
  pixupCasino,
  pixupClient,
  pixupPink,
  pixupYellow,
  categories,
  chat,
  favorites,
  platform,
  searchMenu,
  casino,
  exchange,
  sportsbook,
  favoriteHeart,
  onlinedot,
  tether,
  withdrawableAmount,
  bonusDependent,
  bonusIcon,
};

export const Icon: React.FC<IconProps> = ({
  iconName,
  svgProps = { width: 24, height: 24 },
  disabled,
  className,
  ...props
}) => {
  const classNames = cx(className, {
    "pointer-events-none": disabled,
    "cursor-pointer": props?.onClick,
  });

  const SvgIcon = icons[iconName];
  if (!SvgIcon) return null;
  return (
    <div
      className={`flex flex-row justify-center items-center ${classNames} shrink-0`}
      data-testid={iconName}
      {...props}
    >
      <SvgIcon {...svgProps} aria-hidden="true" />
    </div>
  );
};

export default Icon;
