import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppDispatch } from "../../hooks/redux";
import { registerUser } from "store/auth/authenticationSlice";
import CustomButton from "@components/core-components/button/CustomButton";
import Icon from "@components/core-components/icon";
import Textfield from "@components/core-components/input/Textfield";

interface RegisterFormProps {
  onSuccess: (email: string) => void;
}

const registerSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  username: yup.string().required("Username is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must include lower case letter")
    .matches(/[A-Z]/, "Password must include upper case letter")
    .matches(/\d/, "Password must include at least one number")
    .required("Password is required"),
});

const RegisterForm: React.FC<RegisterFormProps> = ({ onSuccess }) => {
  const dispatch = useAppDispatch();
  const [registerError, setRegisterError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(registerSchema),
  });

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      await dispatch(registerUser(data)).unwrap();
      onSuccess(data.email);
      reset();
    } catch (error) {
      setRegisterError(error as string);
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <Textfield
        type="email"
        placeholder="Email*"
        register={register}
        id="email"
        error={errors.email}
      />
      <Textfield
        type="text"
        placeholder="Username*"
        register={register}
        id="username"
        error={errors.username}
      />
      <Textfield
        type="password"
        placeholder="Password*"
        register={register}
        id="password"
        error={errors.password}
      />
      {registerError && <p className="text-red-500">{registerError}</p>}
      <div className="text-sm text-gray-200 space-y-1">
        <div className="flex flex-row items-center gap-3">
          <Icon iconName="check" svgProps={{ height: 16, width: 16 }} />
          <p>Includes lower and upper case</p>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Icon iconName="check" svgProps={{ height: 16, width: 16 }} />
          <p>At least 1 number</p>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Icon iconName="check" svgProps={{ height: 16, width: 16 }} />
          <p>Minimum 8 characters</p>
        </div>
      </div>
      <CustomButton text="REGISTER" type="submit" />
    </form>
  );
};

export default RegisterForm;
