import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Icon from "../icon";
import { useNavigate } from "react-router-dom";

interface SliderItem {
  id: string | number;
  [key: string]: any;
}

interface ReusableSliderProps {
  title: string;
  items: SliderItem[];
  showViewAll?: boolean;
  renderItem: (item: SliderItem) => ReactNode;
  breakpoints: {
    [key: string]: { slidesPerView: number; spaceBetween: number };
  };
}

function CustomSwiper({
  title,
  items,
  showViewAll = true,
  renderItem,
  breakpoints,
}: ReusableSliderProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const swiperRef = useRef<any>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);

      setIsEnd(swiperRef.current.isEnd);
    }
  }, [items]);

  const handleViewAll = () => {
    navigate(`/casino/${title.toLowerCase()}`);
  };

  return (
    <div>
      <div className="flex justify-between items-center text-white text-base md:text-lg font-black mb-2">
        <span>{title}</span>
        <div className="flex justify-end items-center text-white text-lg gap-6 font-semibold">
          {!isMobile && (
            <div className="flex items-center gap-3">
              <button
                onClick={() => swiperRef.current?.slidePrev()}
                disabled={isBeginning}
                className={`w-9 h-9 flex justify-center items-center bg-gray-800 border border-gray-400 rounded-lg text-sm ${
                  isBeginning ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <Icon
                  iconName="sliderArrowLeft"
                  svgProps={{ width: 16.286, height: 14.476 }}
                />
              </button>
              <button
                onClick={() => swiperRef.current?.slideNext()}
                disabled={isEnd}
                className={`w-9 h-9 flex justify-center items-center bg-gray-800 border border-gray-400 rounded-lg text-sm ${
                  isEnd ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <Icon
                  iconName="sliderArrowRight"
                  svgProps={{ width: 16.286, height: 14.476 }}
                />
              </button>
            </div>
          )}
          {showViewAll && (
            <button
              onClick={handleViewAll}
              className="px-2 md:px-4 h-7 md:h-9 flex justify-center items-center bg-gray-800 border border-gray-400 rounded-lg text-xs md:text-sm"
            >
              View All
            </button>
          )}
        </div>
      </div>
      <Swiper
        breakpoints={breakpoints}
        loop={false}
        onSwiper={(swiper: any) => (swiperRef.current = swiper)}
        onSlideChange={(swiper: any) => {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
        onReachBeginning={() => setIsBeginning(true)}
        onReachEnd={() => setIsEnd(true)}
        onFromEdge={() => {
          setIsBeginning(false);
          setIsEnd(false);
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>{renderItem(item)}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default CustomSwiper;
