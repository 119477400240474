import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseService } from "api/core/baseService";
import { RootState } from "store";

export interface GameResponse {
  category: string;
  provider_name: string;
  game_id: string;
  game_name: string;
  sub_provider_name: string;
  status: string;
  url_thumb: string;
  game_code: string;
  id: string;
}
//test

interface casinoState {
  games: GameResponse[];
  loading: boolean;
  error: string | null;
}

const initialState: casinoState = {
  games: [],
  loading: false,
  error: null,
};

export const fetchGames = createAsyncThunk<GameResponse[]>(
  "players/games",
  async (_, { rejectWithValue }) => {
    try {
      const res = await baseService.getAll<GameResponse[]>("players/games");
      console.log("ress", res);
      return res;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch games");
    }
  }
);

export const casinoSlice = createSlice({
  name: "casino",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGames.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchGames.fulfilled,
        (state, action: PayloadAction<GameResponse[]>) => {
          state.loading = false;
          state.games = action.payload;
        }
      )
      .addCase(fetchGames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});
export const casinoGames = (state: RootState) => state.casino.games;
export default casinoSlice.reducer;
