import React from "react";
import Icon from "../icon";

interface SearchBarProps {
  onClick: () => void;
  classNames?: string;
  value: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onClick,
  value,
  classNames,
}) => {
  return (
    <div
      className={`flex items-center min-h-[50px] gap-4 h-full bg-gray-900 border border-gray-400 rounded-lg pl-4 py-3 w-full ${classNames}`}
    >
      <Icon
        iconName="searchWhite"
        svgProps={{
          width: 16,
          height: 16,
        }}
      />
      <input
        type="text"
        placeholder="Search your game..."
        className="bg-transparent flex-1 text-sm font-medium text-gray-200 placeholder-gray-200 focus:outline-none"
        onClick={onClick}
        value={value}
      />
    </div>
  );
};

export default SearchBar;
