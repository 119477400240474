import React from "react";
import Icon from "../icon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/redux";
import { useDispatch } from "react-redux";
import { setOpenModal } from "store/auth/authenticationSlice";

interface GameCardProps {
  id: number | string;
  name: string;
  image: string;
  altText: string;
  notAvailable: boolean;
  category?: string;
  showPlayCount?: boolean;
}

const GameCard = ({
  id,
  name,
  image,
  altText,
  notAvailable,
  category,
  showPlayCount = false,
}: GameCardProps) => {
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useAppSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (!notAvailable) {
      if (!isAuthenticated) {
        dispatch(setOpenModal(true));
      } else {
        navigate(`/game/${id}/${name}`);
      }
    }
  };

  return (
    <button
      onClick={handleClick}
      className="w-[100px] md:w-[150px] flex-shrink-0 rounded-lg overflow-hidden transition-transform duration-200 hover:scale-105 pointer-events-auto"
      disabled={notAvailable}
    >
      <div className="w-full h-[133px] md:h-[200px] rounded-lg overflow-hidden relative group">
        <div className="absolute inset-0 rounded-lg glow-line group-hover:animate-glow"></div>
        <img
          loading="lazy"
          src={image}
          alt={altText}
          className="w-full h-full object-cover rounded-lg"
        />
        {notAvailable && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-65 rounded-lg backdrop-blur-[2px] text-gray-200 text-center font-semibold px-4">
            <Icon iconName="region" svgProps={{ width: 34, height: 34 }} />
            <p className="mt-2 text-gray-100 font-semibold text-sm">
              Not available in your region
            </p>
          </div>
        )}
      </div>
      {showPlayCount && (
        <div className="flex flex-row text-center mt-3 items-center gap-2">
          <Icon iconName="ellipse20" svgProps={{ width: 7, height: 7 }} />
          <span className="text-white text-xs md:text-sm font-normal">
            3.291 playing
          </span>
        </div>
      )}
    </button>
  );
};

export default GameCard;
