import React from "react";

interface WithdrawMethodButtonProps {
  method: string;
  selectedMethod: string;
  onClick: (method: string) => void;
  iconPath: string;
  iconDimensions: { width: number; height: number };
}

const WithdrawMethodButton: React.FC<WithdrawMethodButtonProps> = ({
  method,
  selectedMethod,
  onClick,
  iconPath,
  iconDimensions,
}) => (
  <button
    onClick={() => onClick(method)}
    className={`flex h-[42px] w-40 items-center justify-center px-4 rounded-lg bg-gray-700 border border-gray-400 hover:bg-gray-600 ${
      selectedMethod === method ? "border-2 border-yellow" : ""
    }`}
  >
    <img
      src={iconPath}
      alt={method}
      width={iconDimensions.width}
      height={iconDimensions.height}
    />
  </button>
);

export default WithdrawMethodButton;
