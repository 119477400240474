import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Icon from "@components/core-components/icon";
import PlatformModal from "@components/core-components/modal/PlatformModal";

function MobileBottomTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPlatformModalOpen, setPlatformModalOpen] = useState(false);

  const isActive = (path: any) => location.pathname === path;

  const togglePlatformModal = () => setPlatformModalOpen(!isPlatformModalOpen);

  return (
    <>
      <div className="fixed bottom-0 left-0 w-full h-[100px] bg-gray-900 flex justify-around items-center shadow-md z-20">
        {[
          { path: "/menu", label: "Menu", iconName: "searchMenu" },
          { path: "/favorites", label: "Favorites", iconName: "favorites" },
          {
            path: "/",
            label: "Platform",
            iconName: "platform",
            onClick: togglePlatformModal,
          },
          { path: "/categories", label: "Categories", iconName: "categories" },
          { path: "/chat", label: "Chat", iconName: "chat" },
        ].map((tab) => (
          <button
            key={tab.path}
            className={`flex flex-col items-center ${
              isActive(tab.path) ||
              (tab.path === "/platform" &&
                ["/sportsbook", "/casino", "/exchange"].includes(
                  location.pathname
                ))
                ? "text-yellow"
                : "text-white"
            }`}
            onClick={() => (tab.onClick ? tab.onClick() : navigate(tab.path))}
          >
            <Icon
              iconName={tab.iconName}
              svgProps={{
                width: tab.iconName === "platform" ? 30 : 24,
                height: tab.iconName === "platform" ? 30 : 24,
                fill:
                  isActive(tab.path) ||
                  (tab.path === "/" &&
                    ["/", "/", "/"].includes(location.pathname))
                    ? "#FDD60B" // yellow
                    : "currentColor",
              }}
              className={tab.iconName === "platform" ? "relative bottom-1" : ""}
            />
            <span className="text-xs mt-2">{tab.label}</span>
          </button>
        ))}
      </div>

      <PlatformModal
        isOpen={isPlatformModalOpen}
        onClose={togglePlatformModal}
      />
    </>
  );
}

export default MobileBottomTabs;
