import React, { ReactNode, useEffect, useRef } from "react";
import Icon from "@components/core-components/icon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: ReactNode;
  footer?: ReactNode;
  title?: any;
  contentClassNames?: string;
  wrapperClassNames?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  content,
  footer,
  title,
  contentClassNames,
  wrapperClassNames,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className={`flex flex-col rounded-xl shadow-lg min-w-[300px] ${wrapperClassNames}`}
        ref={modalRef}
      >
        {title && (
          <div className="flex flex-col justify-center rounded-t-xl h-[70px] px-6 w-full bg-gray-600 text-white border-b border-gray-400">
            <div className="h-[32px] py-6 flex flex-ro justify-between items-center">
              <div className="font-semibold text-heading-20">{title}</div>
              <Icon
                iconName="closeCircle"
                svgProps={{ height: 32, width: 32 }}
                onClick={onClose}
              />
            </div>
          </div>
        )}
        <div className={`bg-gray-600  pb-6 ${contentClassNames}`}>
          {content}
        </div>
        {footer && (
          <div className="flex justify-end w-full pb-6 items-center bg-gray-600 rounded-b-xl">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
