import React from "react";

const TabsComponent = ({
  tabs,
  activeTab,
  isHomeScreen = false,
  setActiveTab,
  classNames,
  activeTabColor = "bg-pink",
}: {
  tabs: { id: string; label: string }[];
  activeTab: string;
  isHomeScreen?: boolean;
  setActiveTab: (tab: string) => void;
  classNames?: string;
  activeTabColor?: "bg-pink" | "bg-primary";
}) => {
  return (
    <>
      {isHomeScreen ? (
        <div
          className={`flex overflow-x-auto whitespace-nowrap rounded-lg w-full ${classNames}`}
          style={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <div className="flex justify-between p-2 gap-1 rounded-lg w-full border-gray-400">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`w-full px-[50px] py-[4px] min-h-8 text-sm font-semibold rounded items-center justify-center transition-colors duration-200 ${
                  activeTab === tab.id
                    ? `text-white ${activeTabColor} ${
                        activeTabColor === "bg-primary"
                          ? "text-gray-600"
                          : "text-white"
                      }`
                    : "text-white hover:bg-gray-400"
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div
          className={`flex items-center justify-between w-full ${classNames}`}
        >
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`w-full min-h-9 text-sm font-semibold rounded-lg items-center justify-center transition-colors duration-200 ${
                activeTab === tab.id
                  ? `text-white ${activeTabColor} ${
                      activeTabColor === "bg-primary"
                        ? "text-gray-600"
                        : "text-white"
                    }`
                  : "text-white hover:bg-gray-400"
              }`}
            >
              {tab.label}
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export default TabsComponent;
