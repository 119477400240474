import React, { useState, useEffect } from "react";

interface CustomButtonProps {
  text: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  buttonType?: "primary" | "secondary";
  type?: "button" | "submit" | "reset";
}

const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  onClick,
  buttonType = "primary",
  type = "button",
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isPrimary = buttonType === "primary";

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <button
      type={type}
      onClick={onClick}
      className={`flex items-center justify-center rounded-[5px] py-2 w-full h-10 transition-transform duration-200 ${
        isPrimary
          ? "bg-gradient-to-b from-[#FFE458] to-[#E1BD00] border-2 border-[#E1BD00] hover:scale-105"
          : "bg-transparent border border-white hover:bg-button-hover"
      } ${isMobile ? "min-w-[75px]" : "min-w-[100px]"}`}
    >
      <span
        className={`font-semibold text-sm ${
          isPrimary ? "text-gray-400" : "text-white"
        }`}
      >
        {text}
      </span>
    </button>
  );
};

export default CustomButton;
