import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TabsComponent from "../../../../components/core-components/TabsComponent";
import { games } from "../../../../data/data";
import Icon from "@components/core-components/icon";
import FavoriteGameCards from "@components/core-components/FavoriteGameCards";
import ChatComponent from "./ChatComponent";

function RightMenu() {
  const [activeTab, setActiveTab] = useState("lastPlays");
  const [isChatVisible, setChatVisible] = useState(false);
  const navigate = useNavigate();
  const lastPlaysGames = games.slice(0, 5);
  const favoritesGames = games.slice(5, 10);

  const gamesToShow =
    activeTab === "lastPlays" ? lastPlaysGames : favoritesGames;

  const tabs = [
    { id: "favorites", label: "Favorites" },
    { id: "lastPlays", label: "Last Plays" },
    { id: "chat", label: "Chat" },
  ];

  const handleNavigate = (id: number, name: string) => {
    navigate(`/game/${id}/${name}`);
  };

  const handleStartChat = () => {
    setChatVisible(true);
  };

  return (
    <aside className="bg-gray-600 w-[270px] min-w-[270px]">
      <TabsComponent
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        classNames="bg-[#12182B] h-[50px] rounded-lg gap-1 px-2"
      />
      <div className="h-full">
        {activeTab === "chat" ? (
          <>
            {isChatVisible ? (
              <ChatComponent />
            ) : (
              <div className="bg-gray-400 text-white p-6 rounded-lg flex flex-col items-center text-center">
                <div className="flex justify-center items-center mb-3">
                  <Icon
                    iconName="support"
                    svgProps={{ width: 32, height: 32 }}
                  />
                </div>
                <h3 className="text-base font-semibold mb-2">Pixup Chat</h3>
                <p className="text-sm font-normal text-gray-100 mb-5">
                  How can I help you?
                </p>
                <button
                  type="button"
                  className="flex items-center justify-center bg-gradient-to-b from-[#FFE458] to-[#E1BD00] border-2 border-[#E1BD00] rounded-[5px] w-full h-10 transition-transform duration-200 hover:scale-105"
                  onClick={handleStartChat}
                >
                  <span className="text-gray-400 font-semibold text-sm">
                    Start Chat
                  </span>
                </button>
              </div>
            )}
          </>
        ) : (
          gamesToShow.map((game) => (
            <FavoriteGameCards
              key={game.id}
              game={game}
              handleNavigate={handleNavigate}
              isFavorite={activeTab === "favorites"}
              toggleFavorite={() => {}}
            />
          ))
        )}
      </div>
    </aside>
  );
}

export default RightMenu;
