import React, { useState, useEffect } from "react";
import TabsComponent from "@components/core-components/TabsComponent";
import RegisterForm from "@components/auth-components/RegisterForm";
import ConfirmForm from "@components/auth-components/ConfirmForm";
import ForgotPasswordForm from "@components/auth-components/ForgotPassword";
import LoginForm from "@components/auth-components/LoginForm";
import Modal from "./modal";
import Icon from "./icon";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialTab?: "signIn" | "register";
}

const AuthModal: React.FC<AuthModalProps> = ({
  isOpen,
  onClose,
  initialTab = "register",
}) => {
  const [activeTab, setActiveTab] = useState<
    "signIn" | "register" | "forgotPassword" | "confirm"
  >(initialTab);
  const [registerEmail, setRegisterEmail] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setActiveTab(initialTab);
    }
  }, [isOpen, initialTab]);

  const renderContent = () => {
    switch (activeTab) {
      case "register":
        return (
          <RegisterForm
            onSuccess={(email) => {
              setRegisterEmail(email);
              setActiveTab("confirm");
            }}
          />
        );
      case "signIn":
        return (
          <LoginForm
            onClose={onClose}
            onForgotPassword={() => setActiveTab("forgotPassword")}
          />
        );
      case "confirm":
        return (
          <ConfirmForm
            email={registerEmail || ""}
            onSuccess={onClose}
            onBack={() => setActiveTab("register")}
          />
        );
      case "forgotPassword":
        return <ForgotPasswordForm onBack={() => setActiveTab("signIn")} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className="flex flex-row gap-1 justify-center items-center w-full max-w-[484px]">
          <Icon
            iconName="pixupYellowLogo"
            svgProps={{ width: 34, height: 23 }}
            className="w-[34px] h-[23px] justify-start"
          />
          <Icon
            iconName="pixupClientText"
            svgProps={{ width: 85, height: 14 }}
            className="w-[85px] h-[14px] justify-start"
          />
        </div>
      }
      content={
        <>
          <div className={`px-6 ${isMobile ? "w-full" : "w-[484px]"}`}>
            <TabsComponent
              tabs={[
                { id: "register", label: "CREATE AN ACCOUNT" },
                { id: "signIn", label: "SIGN IN" },
              ]}
              classNames="mb-4 bg-gray-900 h-12 p-2 rounded-lg gap-2"
              activeTabColor="bg-primary"
              activeTab={activeTab}
              setActiveTab={(tab) => setActiveTab(tab as any)}
            />
          </div>
          <div className={`px-6 ${isMobile ? "w-full" : "w-[484px]"}`}>
            {renderContent()}
          </div>
        </>
      }
      footer={<></>}
    />
  );
};

export default AuthModal;
