import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { games } from "../../../../../data/data";
import FavoriteGameCards from "@components/core-components/FavoriteGameCards";

function Favorites() {
  const navigate = useNavigate();
  const favoritesGames = games.slice(5, 10);

  const [favoriteIds, setFavoriteIds] = useState<number[]>(
    favoritesGames.map((game) => game.id)
  );

  const handleNavigate = (id: number, name: string) => {
    navigate(`/game/${id}/${name}`);
  };

  const toggleFavorite = (id: number) => {
    setFavoriteIds((prevFavorites) =>
      prevFavorites.includes(id)
        ? prevFavorites.filter((favId) => favId !== id)
        : [...prevFavorites, id]
    );
  };

  return (
    <div className="w-full h-full px-3 pt-6 pb-72 overflow-x-hidden main-no-scrollbar">
      <h2 className="text-base font-black mb-4 text-white">FAVORITES</h2>
      <div className="space-y-2">
        {favoritesGames.map((game) => (
          <FavoriteGameCards
            key={game.id}
            game={game}
            handleNavigate={handleNavigate}
            isFavorite={favoriteIds.includes(game.id)}
            toggleFavorite={toggleFavorite}
            isFavoriteIconShown={true}
          />
        ))}
      </div>
    </div>
  );
}

export default Favorites;
