import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Pages from "./pages";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { checkAuthStatus } from "store/auth/authenticationSlice";

function App() {
  const dispatch = useAppDispatch();
  //@ts-ignore
  const { isAuthenticated, loading } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  // return <GameLoginSample />;
  return (
    <>
      <Pages />
    </>
  );
}

export default App;

// const GameLoginSample = () => {
//   const [iframeUrl, setIframeUrl] = useState(""); // Iframe URL'sini saklamak için state

//   useEffect(() => {
//     const requestData = {
//       operatorId: "pixupplay",
//       providerName: "JiLi",
//       gameId: "600040", //600034
//       userId: "1291",
//       username: "cagatayy",
//       platformId: "desktop",
//       lobby: false,
//       clientIp: "188.119.16.245",
//       currency: "INR",
//       balance: 900.33,
//       redirectUrl: "https://staging.pixupplay.com",
//     };

//     // API çağrısı ile URL'yi al
//     axios
//       .post("https://staging.pixupplay.com/casino", requestData)
//       .then((res) => {
//         console.log("API Yanıtı:", res.data);
//         setIframeUrl(res.data.url); // URL'yi state'e kaydet
//       })
//       .catch((error) => {
//         console.error("Hata oluştu:", error);
//       });
//   }, []); // useEffect sadece bileşen yüklendiğinde çalışır

//   return (
//     <div>
//       <h1>Game Login</h1>
//       {/* Iframe URL mevcutsa render et */}
//       {iframeUrl ? (
//         <iframe
//           src={iframeUrl}
//           width="100%"
//           height="600"
//           style={{ border: "none" }}
//         ></iframe>
//       ) : (
//         <p>Loading game...</p>
//       )}
//     </div>
//   );
// };
