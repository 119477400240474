import React, { useEffect, useRef, useState } from "react";
import Footer from "@components/layout-components/Footer";
import Icon from "@components/core-components/icon";
import GameCard from "@components/core-components/card/GameCard";
import "swiper/css";
import { useNavigate, useParams } from "react-router-dom";
import { games } from "../../../../data/data";
import CustomSwiper from "@components/core-components/swiper";
import axios from "axios";
import { baseService } from "api/core/baseService";
import { useSelector } from "react-redux";
import { Wallet } from "@components/layout-components/types";
import { GameResponse } from "store/casino/casinoSlice";
function Game() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { userId } = useSelector((state: any) => state.auth);
  const { gameId } = useParams();

  const swiperRef = useRef<any>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userBalance, setUserBalance] = useState<Wallet>();
  const [selectedGame, setSelectedGame] = useState<GameResponse>();
  const getData = async () => {
    try {
      if (gameId) {
        setLoading(true);
        const res = await baseService.getById<GameResponse>(
          "players/games/",
          Number(gameId)
        );

        setSelectedGame(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const [iframeUrl, setIframeUrl] = useState("");
  const getdata = async () => {
    console.log("userId", userId);
    const wallet = await baseService.getById<Wallet>(
      "users/wallet/active/",
      userId
    );
    setUserBalance(wallet);
    const requestData = {
      operatorId: "pixupplay",
      providerName: "JiLi",
      gameId: gameId,
      userId: userId,
      username: "cagatayy",
      platformId: "desktop",
      lobby: false,
      clientIp: "188.119.16.245",
      currency: wallet?.currency?.shortCut,
      balance: wallet?.total,
      redirectUrl: "https://staging.pixupplay.com",
    };

    axios
      .post("https://staging.pixupplay.com/casino", requestData)
      .then((res) => {
        console.log("API Yanıtı:", res.data);
        setIframeUrl(res.data.url);
      })
      .catch((error) => {
        console.error("Hata oluştu:", error);
      });
  };
  useEffect(() => {
    getdata();
  }, []);

  const tags = [
    "Bonus Buy",
    "Candy",
    "Cascading",
    "Drop & Wins",
    "Fruit",
    "Pragmatic Games",
    "Recommended Games",
    "Sages",
    "Slots",
  ];

  const breakpoints = {
    "0": { slidesPerView: 3, spaceBetween: 0 }, // Mobile screens
    "820": { slidesPerView: 3.5, spaceBetween: 0 },
    "950": { slidesPerView: 2, spaceBetween: 0 },
    "1024": { slidesPerView: 2.5, spaceBetween: 0 },
    "1190": { slidesPerView: 3, spaceBetween: 0 },
    "1200": { slidesPerView: 3.5, spaceBetween: 0 },
    "1260": { slidesPerView: 4.3, spaceBetween: 0 },
    "1550": { slidesPerView: 6, spaceBetween: 0 },
    "1800": { slidesPerView: 7.5, spaceBetween: 0 },
    "2000": { slidesPerView: 9, spaceBetween: 0 },
  };

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreen = () => {
    if (isMobile) {
      setIsFullscreen(!isFullscreen);

      if (!isFullscreen && "orientation" in screen) {
        (screen.orientation as any)
          .lock("landscape")
          .catch((error: any) =>
            console.warn("Yatay moda geçilemiyor:", error)
          );
      } else if (isFullscreen && "orientation" in screen) {
        screen.orientation.unlock();
      }
    } else if (iframeRef.current) {
      const iframeContainer = iframeRef.current.parentElement;
      if (iframeContainer && iframeContainer.requestFullscreen) {
        iframeContainer.requestFullscreen();
      }
    }
  };

  return (
    <main
      className={`flex-grow w-full bg-gray-900 h-screen overflow-y-auto main-no-scrollbar`}
    >
      <div className={`flex flex-col items-center ${isMobile ? "p-0" : "p-9"}`}>
        <div
          className={`flex justify-between items-center pb-5 text-white w-full ${
            isMobile ? "pt-5 px-3" : "pt-0"
          }`}
        >
          {isMobile ? (
            <span className="text-lg font-semibold">{games[0].name}</span>
          ) : (
            <div
              className={`flex items-center gap-2 ${
                isMobile ? "text-sm" : "text-base"
              } font-semibold`}
            >
              <button onClick={() => navigate(`/`)}>Homepage</button>
              <Icon
                iconName="rightArrow"
                svgProps={{ width: 16, height: 12 }}
              />
              <span>{games[0].name}</span>
            </div>
          )}

          <div className={`flex ${isMobile ? "gap-8" : "gap-4"}`}>
            <button onClick={() => console.log("Added to favorites")}>
              <Icon
                iconName="favorite"
                svgProps={{
                  width: 26,
                  height: 26,
                }}
              />
            </button>
            <button onClick={handleFullscreen}>
              <Icon
                iconName="fullscreen"
                svgProps={{
                  width: 18,
                  height: 18,
                }}
              />
            </button>
            <button onClick={() => navigate(`/`)}>
              <Icon
                iconName="closeIcon"
                svgProps={{
                  width: 20,
                  height: 20,
                }}
              />
            </button>
          </div>
        </div>
        <div
          className={`w-full ${
            isMobile ? "rounded-none" : "max-w-screen-xxl bg-black rounded-lg"
          } overflow-hidden mb-4 ${
            isFullscreen ? "fixed top-0 left-0 w-full h-full z-50" : ""
          }`}
          style={{
            height:
              isMobile && isFullscreen ? "100vh" : isMobile ? "300px" : "600px",
          }}
        >
          {loading ? (
            <div
              className="flex items-center justify-center text-white"
              style={{ minHeight: isMobile ? "300px" : "700px" }}
            >
              Loading game...
            </div>
          ) : (
            <iframe
              ref={iframeRef}
              src={iframeUrl}
              width="100%"
              height="100%"
              style={{ border: "none" }}
              title="Game Frame"
            ></iframe>
          )}
        </div>
        <div
          className={`flex ${
            isMobile ? "flex-col" : "flex-row"
          } gap-4 w-full max-w-screen-xxl bg-gray-700 rounded-lg p-4 text-white`}
        >
          <img
            src={selectedGame?.url_thumb}
            alt={selectedGame?.game_name}
            className={`${isMobile ? "w-24 h-24" : "w-36 h-36"} rounded-lg`}
          />
          <div className="w-full">
            <span className="text-lg font-black text-orange">
              Pragmatic Play
            </span>
            <h2
              className={`${
                isMobile ? "text-2xl" : "text-4xl"
              } font-black mt-2`}
            >
              {selectedGame?.game_name}
            </h2>
            <div className="flex flex-wrap gap-2 mt-3">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-gray-400 text-xs px-3 py-1 rounded-lg"
                >
                  {tag}
                </span>
              ))}
            </div>
            <div className="gap-8 mt-4 max-w-screen-sm">
              <p className="text-sm font-normal mb-2 text-gray-200">
                Sweet Bonanza 1000 is a makeover of Pragmatic Play’s popular
                video slot, Sweet Bonanza.
              </p>
              <p className="text-sm font-normal mb-2 text-gray-200">
                The new version of the renowned candy-themed game uses a 6x5
                grid, has a max potential payout of 25,000x the pixup, and has
                no set paylines, as it pays anywhere.
              </p>
              <p className="text-sm font-normal mb-2 text-gray-200">
                Let’s unpack the exciting gameplay and game details of this
                reimagination of the beloved candy-themed slot machine now
                available at Pixup Casino !
              </p>
              <h3 className="text-lg font-black mb-2">
                How to Play Sweet Bonanza 1000 & Gameplay
              </h3>
              <p className="text-sm font-normal mb-2 text-gray-200">
                You can choose to play this online slot game in free-play mode
                or start wagering funds right away.
              </p>
              <p className="text-sm font-normal mb-2 text-gray-200">
                The demo game is an excellent way to learn the rules and
                features of a game, but our how-to-play online slots guide can
                also help you master the basics. While you are in a reading
                mood, check out the online casino guide to learn more about how
                to navigate Pixup.com .
              </p>
              <p className="text-sm font-normal mb-2 text-gray-200">
                Once you gain sufficient confidence in the gameplay, load the
                game and choose your bet. The plus and minus buttons are
                prominent in the menu, allowing you to adjust your bet amount.
                The spin button will start each spin. You secure a win if 8 or
                more matching symbols align anywhere on the reels. The outcome
                of spins is random, and an RNG system regulates that chance
                effect.
              </p>
            </div>
          </div>
          {!isMobile && (
            <button className="flex w-auto items-center bg-transparent h-7 text-white gap-4 mt-4">
              <Icon iconName="favorite" svgProps={{ width: 26, height: 26 }} />
              <span className="text-base font-black whitespace-nowrap">
                Add Favorite
              </span>
            </button>
          )}
        </div>

        <div className={`pt-8 pb-72 w-full ${isMobile ? "px-3" : "px-0"} `}>
          <CustomSwiper
            title="RECOMMENDED GAMES"
            items={games}
            breakpoints={breakpoints}
            renderItem={(game) => (
              <GameCard
                image={game.image}
                id={game.id}
                name={game.name}
                altText={game.name}
                notAvailable={game.notAvailable}
                showPlayCount={true}
              />
            )}
          />
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default Game;
