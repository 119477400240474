import Icon from "@components/core-components/icon";
import Aviator from "../../../../assets/images/aviator.png";
import EmojiInput from "./EmojiInput";

const messages = [
  {
    id: 1,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 2,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 3,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 4,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 5,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 6,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 7,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 8,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 9,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
];

const onlineCount = 44999;

export default function ChatComponent() {
  return (
    <div className="bg-[#1D243E]">
      <div className="h-[47px] flex flex-row items-center gap-2 px-4 bg-[#222A45]">
        <Icon iconName="onlinedot" svgProps={{ width: 7, height: 7 }} />
        <span className="font-normal text-body-reg-13 text-[#CCD6F4]">
          Online: {onlineCount}
        </span>
      </div>
      <ul className="w-full px-4 gap-1 flex flex-col overflow-y-auto mt-[13px] h-[calc(100vh-277px)]">
        {messages.map((item) => (
          <li
            key={item.id}
            className="bg-[#2C3555] w-full rounded-lg flex flex-row items-start py-3 px-[13px] gap-2"
          >
            <div className="flex justify-center items-center">
              <img
                loading="lazy"
                className="w-12 h-4 rounded-full object-cover"
                src={item.avatar}
              />
            </div>

            <span className="text-[#CCD6F4] font-normal text-body-reg-13">
              <span className="text-[#CCD6F4] font-bold text-body-reg-13">
                {item.name}:
              </span>{" "}
              {item.message}
            </span>
          </li>
        ))}
      </ul>
      <div className="h-[102px] w-full bg-green flex flex-col justify-center border-[#525C7C] border-t gap-2 p-4 ">
        <div className="h-[50px] flex flex-row bg-green items-center gap-4">
          <EmojiInput />
          <button className="h-full w-[60px] bg-[#FFE458] border-[2px] border-[#E1BD00] flex items-center justify-center rounded-md text-body-med-14 font-semibold text-[#2C3555]">
            Send
          </button>
        </div>
        <span className="text-[#CCD6F4] font-regular text-body-reg-12">
          160 Characters
        </span>
      </div>
    </div>
  );
}
