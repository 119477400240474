import CategoryList from "@components/core-components/CategoryList";
import React, { useState } from "react";

function Categories() {
  const [activeCategory, setActiveCategory] = useState("");

  return (
    <div className="w-full h-full pt-6 pb-72 overflow-x-hidden main-no-scrollbar">
      <h2 className="text-base font-black mb-4 text-white px-3">MENU</h2>
      <CategoryList
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
    </div>
  );
}

export default Categories;
