import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import casinoBonus2 from "../../../../assets/images/test-carousel.png";
import { useRef } from "react";
import Icon from "../../../../components/core-components/icon";

const photos = [
  casinoBonus2,
  casinoBonus2,
  casinoBonus2,
  casinoBonus2,
  casinoBonus2,
];

const Carousel = () => {
  const swiperRef = useRef<any>(null);
  return (
    <div className="w-full max-h-[280px] relative overflow-visible p-0">
      <Swiper
        ref={swiperRef}
        className="h-full"
        modules={[Pagination]}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1440: {
            slidesPerView: 2,
          },
          1680: {
            slidesPerView: 3,
          },
          1920: {
            slidesPerView: 3,
          },
        }}
        autoplay={{ delay: 2000 }}
        loop
      >
        {photos.map((photo, index) => (
          <SwiperSlide key={index} className="flex items-center justify-center">
            <img
              src={photo}
              alt={`Slide ${index + 1}`}
              className="w-[450px] h-[180px] rounded-lg"
            />
          </SwiperSlide>
        ))}
        <button
          className="custom-next absolute top-[40%] right-[15px] cursor-pointer h-10 w-10 z-10 justify-center items-center bg-gray-800 border border-gray-400 rounded-lg text-sm hidden md:flex"
          onClick={() => swiperRef.current.swiper.slideNext()}
        >
          <Icon
            iconName="carouselRightIcon"
            svgProps={{ height: 15, width: 18 }}
          />
        </button>
      </Swiper>
    </div>
  );
};

export default Carousel;
