import React from "react";

interface ProviderCardProps {
  logo: string;
  name: string;
}

const ProviderCard = ({ logo, name }: ProviderCardProps) => (
  <div className="w-[90px] md:w-36 h-12 md:h-20 bg-[#1D243E] flex items-center justify-center rounded-lg transition-transform duration-200 hover:scale-105">
    <img src={logo} alt={name} className="object-contain p-3" />
  </div>
);

export default ProviderCard;
