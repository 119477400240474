import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAppDispatch } from "../../hooks/redux";
import { confirmUser } from "store/auth/authenticationSlice";
import Icon from "@components/core-components/icon";
import Textfield from "@components/core-components/input/Textfield";
import CustomButton from "@components/core-components/button/CustomButton";

interface ConfirmFormProps {
  email: string;
  onSuccess: () => void;
  onBack: () => void;
}

const confirmSchema = yup.object().shape({
  confirmCode: yup.string().required("Confirmation code is required"),
});

const ConfirmForm: React.FC<ConfirmFormProps> = ({
  email,
  onSuccess,
  onBack,
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(confirmSchema),
  });

  const onSubmit: SubmitHandler<any> = async (data) => {
    await dispatch(
      confirmUser({ email, confirmationCode: data.confirmCode })
    ).unwrap();
    onSuccess();
    reset();
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-2">
        <button type="button" onClick={onBack} className="text-white">
          <Icon iconName="backSquare" svgProps={{ width: 20, height: 20 }} />
        </button>
        <h2 className="text-white font-semibold text-lg">Confirm Account</h2>
      </div>
      <Textfield
        type="text"
        placeholder="Confirmation Code*"
        register={register}
        id="confirmCode"
        error={errors.confirmCode}
      />
      <CustomButton text="CONFIRM" type="submit" />
    </form>
  );
};

export default ConfirmForm;
