// import React, { useState, useRef, useEffect } from "react";
// import Icon from "../icon";

import { useEffect, useRef, useState } from "react";
import Icon from "../icon";
import CustomButton from "./CustomButton";

interface DropdownButtonProps {
  iconName: string;
  label: string;
  options: {
    label: string;
    onClick: () => void;
    icon?: string;
    value?: string;
  }[];
  iconWidth?: number;
  iconHeight?: number;
  footer?: any;
  dropdownWidth?: string;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  iconName,
  label,
  options,
  iconWidth = 20,
  iconHeight = 20,
  footer,
  dropdownWidth = "auto",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="relative h-full">
      <button
        className={`flex items-center gap-2 h-full text-white text-sm font-black relative whitespace-nowrap ${
          isOpen ? "border-b-[3px] border-yellow bg-gray-500" : ""
        } px-4 md:px-6`}
        onClick={toggleDropdown}
      >
        <Icon
          iconName={iconName}
          svgProps={{ width: iconWidth, height: iconHeight }}
        />
        {label}
        {iconName !== "profileIconWhite" && (
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </button>

      {isOpen && (
        <>
          {isMobile && (
            <div
              className="fixed left-0 right-0 bottom-0 bg-black opacity-50 z-10"
              style={{
                top: dropdownRef.current?.getBoundingClientRect().bottom,
              }}
            ></div>
          )}
          <div
            className={`origin-top-right absolute right-0 bg-gray-500 text-gray-100 focus:outline-none ring-1 ring-black ring-opacity-5 z-20 ${
              isMobile ? "w-screen left-0" : "w-64"
            }`}
            style={{
              minWidth:
                dropdownWidth || dropdownRef.current?.offsetWidth || "auto",
              top: "100%",
              marginTop: "0px",
            }}
            onMouseLeave={() => setIsOpen(false)}
          >
            <div className="py-2">
              {options.map((option: any, index: number) => (
                <button
                  key={index}
                  onClick={() => {
                    option.onClick();
                    setIsOpen(false);
                  }}
                  className="flex items-center text-left w-full justify-between py-4 px-6 text-sm font-semibold hover:bg-gray-700 border-b border-gray-300"
                >
                  <div className="flex w-full items-center gap-3">
                    {option.icon && (
                      <Icon
                        iconName={option.icon}
                        svgProps={{ width: 17, height: 17 }}
                      />
                    )}
                    {option.label}
                  </div>
                  <span className="flex flex-row justify-end text-white font-semibold text-sm w-40">
                    {option.value}
                  </span>
                </button>
              ))}
            </div>
            {footer && footer}
          </div>
        </>
      )}
    </div>
  );
};

export default DropdownButton;

/* 
interface DropdownButtonProps {
  iconName: string;
  label: string;
  options: {
    label: string;
    onClick: () => void;
    icon?: string;
    value?: string;
  }[];
  iconWidth?: number;
  iconHeight?: number;
  isFooterShown?: boolean;
  dropdownWidth?: string;
}
const DropdownButton: React.FC<DropdownButtonProps> = ({
  iconName,
  label,
  options,
  iconWidth = 20,
  iconHeight = 20,
  isFooterShown = false,
  dropdownWidth = "auto",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="relative h-full">
      <button
        className={`flex items-center gap-2 h-full text-white text-sm font-black relative whitespace-nowrap ${
          isOpen ? "border-b-[3px] border-yellow bg-gray-500" : ""
        } px-4 md:px-6`}
        onClick={toggleDropdown}
      >
        <Icon
          iconName={iconName}
          svgProps={{ width: iconWidth, height: iconHeight }}
        />
        {label}
        <svg
          style={{ width: 40, height: 50 }}
          className="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          className={`origin-top-right absolute right-0 bg-gray-500 text-gray-100 focus:outline-none ring-1 ring-black ring-opacity-5 z-20`}
          style={{
            minWidth:
              dropdownWidth || dropdownRef.current?.offsetWidth || "auto",
            top: "100%",
            marginTop: "0px",
          }}
          onMouseLeave={() => setIsOpen(false)}
        >
          <div className="py-2">
            {options.map((option: any, index: number) => (
              <button
                key={index}
                onClick={() => {
                  option.onClick();
                  setIsOpen(false);
                }}
                className="flex items-center text-left w-full justify-between py-4 px-6 text-sm font-semibold hover:bg-gray-700 border-b border-gray-300"
              >
                <div className="flex w-full items-center gap-3">
                  {option.icon && (
                    <Icon
                      iconName={option.icon}
                      svgProps={{ width: 17, height: 17 }}
                    />
                  )}
                  {option.label}
                </div>
                <span className="flex flex-row text-white font-semibold text-sm w-">
                  {option.value}
                </span>
              </button>
            ))}
          </div>
          {isFooterShown && (
            <div className="flex flex-row justify-center py-2 px-3 gap-3">
              <CustomButton
                text="Withdraw"
                onClick={() => console.log("Register")}
                buttonType="secondary"
              />
              <CustomButton
                text="Deposit"
                onClick={() => console.log("Register")}
                buttonType="primary"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownButton; */
