import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-theme-alpine.min.css";
import "ag-grid-community/styles/ag-grid.css";
import TabsComponent from "../../../../components/core-components/TabsComponent";
import { useState } from "react";
import Icon from "../../../../components/core-components/icon";

const CasinoTable = () => {
  const [activeTab, setActiveTab] = useState("allbets");
  const [ghostModeEnabled, setGhostModeEnabled] = useState(false);
  const columnDefs: any = [
    {
      headerName: "GAME",
      field: "game",
      cellClass: "flex flex-col justify-center",
      flex: 1,
    },
    {
      headerName: "USER",
      field: "user",
      cellClass: "flex flex-col justify-center",
      flex: 1,
    },
    {
      headerName: "TIME",
      field: "time",
      cellClass: "flex flex-col justify-center",
      flex: 1,
    },
    {
      headerName: "BET AMOUNT",
      field: "betAmount",
      cellClass: "flex flex-col justify-center",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row">
            {params.value}
            <Icon iconName="usdt2" className="ml-3 mt-[2px]" />
          </div>
        );
      },
    },
    {
      headerName: "MULTIPLIER",
      field: "multiplier",
      cellClass: "flex flex-col justify-center",
      flex: 1,
    },
    {
      headerName: "PAYOUT",
      field: "payout",
      cellClass: "flex flex-col justify-center",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row">
            {params.value}
            <Icon iconName="usdt2" className="ml-3 mt-[2px]" />
          </div>
        );
      },
    },
  ];

  const rowData = [
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
  ];
  const tabs = [
    { id: "allbets", label: "ALL BETS" },
    { id: "mybets", label: "MY BETS" },
    { id: "rollers", label: "HIGH ROLLERS" },
    { id: "race", label: "RACE LEADERBOARD" },
  ];

  return (
    <div className="h-[700px] flex flex-col justify-start bg-[#1D243E] items-center rounded-xl">
      <div className="h-12 mt-6 px-8 mb-8 flex flex-row items-center justify-between w-full gap-8">
        <TabsComponent
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isHomeScreen
          classNames="bg-[#12182B] h-full rounded-lg  px-2"
        />
        <div className="h-full flex flex-row ">
          <button
            className="h-full bg-[#2C3555] w-[185px] rounded-lg px-5 py-[14px] mr-4 flex flex-row justify-center items-center text-body-reg-14 font-semibold text-white"
            onClick={() => setGhostModeEnabled(!ghostModeEnabled)}
          >
            <Icon iconName="ghost" className="mt-1 mr-4" /> Ghost Mode{" "}
            {ghostModeEnabled ? "On" : "Off"}
          </button>
          <button className="h-full bg-[#2C3555] w-16 gap-3 rounded-lg flex flex-row justify-center items-center text-body-reg-14 font-semibold text-white">
            10 <Icon iconName="downArrow" className="mt-[5px]" />
          </button>
        </div>
      </div>
      <div
        className="ag-theme-alpine my-custom-ag-grid-wrapper rounded-lg border border-gray-800"
        style={{ height: "600px", width: "100%" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          headerHeight={50}
          rowHeight={50}
          defaultColDef={{
            sortable: false,
          }}
        />
      </div>
    </div>
  );
};

export default CasinoTable;
