import React from "react";
import Icon from "@components/core-components/icon";

interface MenuListProps {
  activeCategory: string;
  setActiveCategory: (category: string) => void;
}

const menuItems: { [key: string]: string } = {
  Profile: "profileIcon",
  Promotions: "promotionIcon",
  "VIP Club": "vipClub",
  Sponsorship: "sponsorship",
  Affiliate: "affiliate",
  Blog: "blog",
  Forum: "forum",
  "Responsible Gambling": "responsibleGambling",
};

const MenuList: React.FC<MenuListProps> = ({
  activeCategory,
  setActiveCategory,
}) => {
  return (
    <div className="mt-4 pb-4 md:border-b border-gray-300">
      {Object.keys(menuItems).map((item) => (
        <button
          key={item}
          onClick={() => setActiveCategory(item)}
          className={`flex items-center text-start w-full h-[53px] text-sm gap-3 font-semibold px-3 transition-colors duration-200 ${
            activeCategory === item
              ? "text-white bg-gray-900 border-l-4 border-[#C13EDA]"
              : "text-gray-200 hover:bg-gray-400"
          }`}
        >
          <div className="w-full flex flex-row items-center justify-between">
            <div className="flex items-center gap-3">
              <Icon
                iconName={menuItems[item]}
                svgProps={{
                  width: 17,
                  height: 17,
                }}
              />
              <span style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                {item}
              </span>
            </div>
            <Icon
              iconName="rightArrow"
              svgProps={{
                width: 12,
                height: 12,
              }}
            />
          </div>
        </button>
      ))}
    </div>
  );
};

export default MenuList;
