import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GameCard from "../../../../components/core-components/card/GameCard";
import { games } from "../../../../data/data";
import Icon from "@components/core-components/icon";
import SearchBar from "@components/core-components/input/SearchBar";
import axiosInstance from "config/axiosInstance";
import Footer from "@components/layout-components/Footer";

function CategoryDetails() {
  const [data, setData] = useState<any>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { categoryName } = useParams();
  const filteredGames = games.filter(
    (game) => game.category?.toLowerCase() === categoryName?.toLowerCase()
  );

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const [isSearchModalVisible, setSearchModalVisible] =
    useState<boolean>(false);

  const handleOpenSearchModal = () =>
    setSearchModalVisible(!isSearchModalVisible);

  const getData = async () => {
    try {
      const res = await axiosInstance.get("players/games");

      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="flex-grow w-full bg-gray-700 h-screen overflow-x-hidden main-no-scrollbar">
      <div className="relative w-full h-14 md:h-28 flex items-center justify-between px-4 md:px-8 bg-custom-gradient-purple-to-pink">
        <div>
          {!isMobile && (
            <div className="flex items-center text-white text-sm font-semibold mb-4">
              <span>Casino</span>
              <Icon
                iconName="rightArrow"
                svgProps={{
                  width: 6,
                  height: 9,
                  className: "mx-2",
                }}
              />
              <span>
                {categoryName ? capitalizeFirstLetter(categoryName) : ""}
              </span>
            </div>
          )}
          <div className="flex items-center gap-1">
            <h1 className="text-xl md:text-3xl font-black text-white uppercase drop-shadow-4xl">
              {categoryName}
            </h1>
            <span className="text-sm font-semibold text-gray-100">
              - 189 Games
            </span>
          </div>
        </div>
        {!isMobile && (
          <Icon
            iconName="gradientBgLogo"
            svgProps={{ width: 140, height: 92 }}
          />
        )}
      </div>
      <div className="md:p-8 p-3 pb-72 w-full bg-gray-900 text-white">
        <div className="flex w-full items-center justify-between mb-6">
          <SearchBar onClick={handleOpenSearchModal} value="" />
        </div>

        <div className="flex justify-between items-center mb-6">
          {isMobile ? (
            <button className="h-10 bg-[#2C3555] w-32 gap-3 rounded-lg flex flex-row justify-center items-center text-sm font-semibold text-white">
              Providers
              <Icon
                iconName="downArrow"
                svgProps={{ width: 16, height: 16, className: "ml-1" }}
              />
            </button>
          ) : (
            <div className="flex items-center gap-2">
              <Icon iconName="filterBy" svgProps={{ width: 17, height: 17 }} />
              <span className="text-white text-sm font-semibold">
                Filter By:
              </span>
              <button className="h-10 bg-[#2C3555] w-32 gap-3 rounded-lg flex flex-row justify-center items-center text-sm font-semibold text-white">
                Providers
                <Icon
                  iconName="downArrow"
                  svgProps={{ width: 16, height: 16, className: "ml-1" }}
                />
              </button>
            </div>
          )}
          {isMobile ? (
            <button className="h-10 bg-[#2C3555] w-32 gap-3 rounded-lg flex flex-row justify-center items-center text-sm font-semibold text-white">
              Popular
              <Icon
                iconName="downArrow"
                svgProps={{ width: 16, height: 16, className: "ml-1" }}
              />
            </button>
          ) : (
            <div className="flex items-center gap-2">
              <Icon iconName="sortBy" svgProps={{ width: 17, height: 17 }} />
              <span className="text-white text-sm font-semibold">Sort By:</span>
              <button className="h-10 bg-[#2C3555] w-32 gap-3 rounded-lg flex flex-row justify-center items-center text-sm font-semibold text-white">
                Popular
                <Icon
                  iconName="downArrow"
                  svgProps={{ width: 16, height: 16, className: "ml-1" }}
                />
              </button>
            </div>
          )}
        </div>
        <div className="flex flex-wrap gap-y-6 md:gap-x-6 lg:gap-x-4 justify-between md:pb-96">
          {data.map((game: any) => (
            <GameCard
              key={game.id}
              id={game.id}
              name={game.name}
              image={game.image}
              altText={game.name}
              notAvailable={game.notAvailable}
              category={game.category}
              showPlayCount={true}
            />
          ))}
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default CategoryDetails;
