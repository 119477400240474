import React, { useState } from "react";
// import { Picker, BaseEmoji } from "emoji-mart";
// import "emoji-mart/css/emoji-mart.css";

const EmojiInput: React.FC = () => {
  const [inputValue, setInputValue] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  //   const handleEmojiSelect = (emoji: BaseEmoji) => {
  //     setInputValue((prev) => prev + emoji.native);
  //     setShowPicker(false);
  //   };

  return (
    <div className="h-full relative">
      <div className="h-full w-full">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type a message"
          className="border p-2 rounded w-full h-full relative"
        />

        <button
          type="button"
          onClick={() => setShowPicker((prev) => !prev)}
          className="absolute rounded right-2 top-[13px]"
        >
          😊
        </button>
      </div>
      {showPicker && (
        <div className="absolute bottom-[380px] right-[220px] w-11 h-[100px] bg-white">
          {/* <Picker onSelect={handleEmojiSelect} title="" /> */}
        </div>
      )}
    </div>
  );
};

export default EmojiInput;
