// PrivateLayout.jsx
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "@components/layout-components/Header";
import HeaderMobile from "@components/layout-components/HeaderMobile";
import LeftMenu from "./casino/components/LeftMenu";
import RightMenu from "./casino/components/RightMenu";
import MobileBottomTabs from "@components/layout-components/MobileBottomTabs";

function PrivateLayout() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex-grow flex flex-col relative h-screen overflow-y-hidden">
      {isMobile ? <HeaderMobile /> : <Header />}
      <div
        className={`flex ${
          isMobile ? "mt-[116px] mb-[60px]" : "mt-[60px]"
        } h-full w-screen`}
      >
        {isMobile ? null : <LeftMenu />}
        <Outlet />
        {isMobile ? null : <RightMenu />}
      </div>
      {isMobile && <MobileBottomTabs />}
    </div>
  );
}

export default PrivateLayout;
