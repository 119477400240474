import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateLayout from "./private";
import Game from "./private/casino/components/Game";
import Main from "./private/casino/components/Main";
import CategoryDetails from "./private/casino/components/CategoryDetails";
import MenuPage from "./private/casino/pages/menu";
import FavoritesPage from "./private/casino/pages/favorites";
import CategoriesPage from "./private/casino/pages/categories";
import ChatPage from "./private/casino/pages/chat";
import { useAppSelector } from "hooks/redux";

function Pages() {
  const { isAuthenticated, loading } = useAppSelector((state) => state.auth);
  return (
    <Routes>
      <Route element={<PrivateLayout />}>
        <Route path="/" element={<Main />} />
        <Route
          path="/game/:gameId/*"
          element={isAuthenticated ? <Game /> : <Main />}
        />
        <Route path="/casino/:categoryName/*" element={<CategoryDetails />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/favorites" element={<FavoritesPage />} />
        <Route path="/categories" element={<CategoriesPage />} />
        <Route path="/chat" element={<ChatPage />} />
      </Route>
    </Routes>
  );
}

export default Pages;
