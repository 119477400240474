import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import authService from "api/auth/AuthService";

export const authenticationLogin = createAsyncThunk(
  "auth/login",
  async (
    credentials: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await authService.login(
        credentials.email,
        credentials.password
      );
      return { response, credentials };
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        console.log("error.response.data", error.response.data);

        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Invalid credentials");
    }
  }
);
export const registerUser = createAsyncThunk(
  "auth/register",
  async (
    credentials: { email: string; password: string; username: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await authService.register(
        credentials.email,
        credentials.password,
        credentials.username
      );
      return { response, credentials };
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkAuthStatus = createAsyncThunk(
  "auth/checkAuthStatus",
  async () => {
    const response = await authService.checkAuthStatus();
    console.log("response", response);

    return response;
  }
);
export const confirmUser = createAsyncThunk(
  "auth/confirm",
  async (
    credentials: { email: string; confirmationCode: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await authService.confirm(
        credentials.email,
        credentials.confirmationCode
      );
      return response;
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await authService.logout();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
interface AuthenticationState {
  userId: string | null;
  loading: boolean;
  error: string | null;
  openModal: boolean;
  isAuthenticated: boolean;
}

const initialState: AuthenticationState = {
  userId: null,
  loading: false,
  openModal: false,
  error: null,
  isAuthenticated: false,
};

export const authenticationSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.userId = null;
    },
    setOpenModal: (state, action: PayloadAction<boolean>) => {
      state.openModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticationLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isAuthenticated = false;
      })
      .addCase(
        authenticationLogin.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.isAuthenticated = true;
          state.userId = action.payload.response.data.playerId;
          state.error = null;
        }
      )
      .addCase(
        authenticationLogin.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error =
            action.payload || "Something went wrong. Please try again.";
          state.isAuthenticated = false;
        }
      )
      .addCase(checkAuthStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        checkAuthStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.userId = action.payload.playerId;
          state.loading = false;
          state.isAuthenticated = action.payload.auth;
        }
      )
      .addCase(checkAuthStatus.rejected, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.userId = null;
      })
      .addCase(logoutUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, setOpenModal } = authenticationSlice.actions;
export default authenticationSlice.reducer;
