import Icon from "@components/core-components/icon";
import { useState, useEffect } from "react";

interface Item {
  heading: string;
  subItems: string[];
  id: number;
}

const data = [
  {
    heading: "SPORTSBOOK",
    id: 1,
    subItems: [
      "Subcategory 1",
      "Subcategory 2",
      "Subcategory 3",
      "Subcategory 4",
    ],
  },
  {
    heading: "LIVEBET",
    id: 2,
    subItems: [
      "Subcategory 1",
      "Subcategory 2",
      "Subcategory 3",
      "Subcategory 4",
    ],
  },
  {
    heading: "LIVE CASINO",
    id: 3,
    subItems: [
      "Subcategory 1",
      "Subcategory 2",
      "Subcategory 3",
      "Subcategory 4",
    ],
  },
  {
    heading: "CASINO",
    id: 4,
    subItems: [
      "Subcategory 1",
      "Subcategory 2",
      "Subcategory 3",
      "Subcategory 4",
    ],
  },
  {
    heading: "VIRTUAL SPORTS",
    id: 5,
    subItems: [
      "Subcategory 1",
      "Subcategory 2",
      "Subcategory 3",
      "Subcategory 4",
    ],
  },
  {
    heading: "JACKPOTS",
    id: 6,
    subItems: [
      "Subcategory 1",
      "Subcategory 2",
      "Subcategory 3",
      "Subcategory 4",
    ],
  },
  {
    heading: "POKER",
    id: 7,
    subItems: [
      "Subcategory 1",
      "Subcategory 2",
      "Subcategory 3",
      "Subcategory 4",
    ],
  },
];

function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function generateItems() {
    return data.map((item: Item) => (
      <div className="flex flex-col items-start" key={item.id}>
        <div className="font-black text-heading-16 text-white mb-6">
          {item.heading}
        </div>
        {generateSubItems(item)}
      </div>
    ));
  }

  function generateSubItems(item: Item) {
    return (
      <div className="flex flex-col gap-4">
        {item.subItems.map((subItem: string) => (
          <a
            key={subItem}
            className="text-gray-100 font-regular text-body-reg-14 cursor-pointer"
          >
            {subItem}
          </a>
        ))}
      </div>
    );
  }

  return (
    <>
      {isMobile ? (
        <div className="flex flex-col bg-gray-600 text-white py-9 px-4 h-full">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-end">
              <Icon
                iconName="pixupYellowLogo"
                svgProps={{ width: 34, height: 23 }}
                className="mr-2"
              />
              <Icon
                iconName="pixupClientText"
                svgProps={{ width: 85, height: 14 }}
                className="w-[85px] h-[14px] justify-start"
              />
            </div>
            <div className="flex gap-3">
              <Icon iconName="facebook" svgProps={{ width: 24, height: 24 }} />
              <Icon iconName="whatsapp" svgProps={{ width: 24, height: 24 }} />
            </div>
          </div>
          <hr className="border-t border-gray-400 mb-6" />
          <div className="flex flex-col gap-4 mb-6">
            {data.map((item) => (
              <a
                key={item.id}
                className="text-base font-semibold uppercase tracking-wider"
              >
                {item.heading}
              </a>
            ))}
          </div>
          <hr className="border-t border-gray-400 mb-6" />
          {/* Footer Bottom Text */}
          <div className="text-start text-gray-200 text-sm mb-6">
            © 2024 Pixup.client | All Rights Reserved.
          </div>

          {/* Footer Links */}
          <div className="flex flex-wrap justify-start gap-2 text-xs text-white pb-60">
            <a href="#" className="hover:underline">
              Terms and Conditions
            </a>
            <span>|</span>
            <a href="#" className="hover:underline">
              Game Rules
            </a>
            <span>|</span>
            <a href="#" className="hover:underline">
              Cookie Policy
            </a>
            <span>|</span>
            <a href="#" className="hover:underline">
              Confidentiality Agreement
            </a>
            <span>|</span>
            <a href="#" className="hover:underline">
              Conscious Play
            </a>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-[450px] w-full bg-gray-600 border-x-2 border-x-gray-500 shadow-xl px-6 pt-[40px]">
          <div className="w-full flex flex-row justify-between">
            <div className="flex flex-row gap-1 justify-center items-center">
              <Icon
                iconName="pixupYellowLogo"
                svgProps={{ width: 34, height: 23 }}
                className="w-[34px] h-[23px] justify-start"
              />
              <Icon
                iconName="pixupClientText"
                svgProps={{ width: 85, height: 14 }}
                className="w-[85px] h-[14px] justify-start"
              />
            </div>
            <div className="flex flex-row w-[73px] justify-between">
              <Icon
                iconName="facebook"
                className="cursor-pointer"
                svgProps={{ width: 24, height: 24 }}
              />
              <Icon
                iconName="whatsapp"
                className="cursor-pointer"
                svgProps={{ width: 24, height: 24 }}
              />
            </div>
          </div>

          <hr className="w-full my-8 border-gray-300" />
          <div className="w-full flex flex-row justify-between ">
            {generateItems()}
          </div>
          <hr className="w-full my-8 border-gray-300" />
          <div className="h-4 flex flex-row w-full justify-between">
            <span className="text-[#CCD6F4] text-body-reg-14 font-normal">
              © 2024 Pixup.client | All Rights Reserved.
            </span>
            <div className="flex flex-row gap-2 items-center h-4">
              <a className="text-body-reg-14 font-normal text-white">
                Terms and Conditions
              </a>
              <hr className="h-full w-[1px] bg-white" />
              <a className="text-body-reg-14 font-normal text-white">
                Game Rules
              </a>
              <hr className="h-full w-[1px] bg-white" />
              <a className="text-body-reg-14 font-normal text-white">
                Cookie Policy
              </a>
              <hr className="h-full w-[1px] bg-white" />
              <a className="text-body-reg-14 font-normal text-white">
                Confidentiality Agreement
              </a>
              <hr className="h-full w-[1px] bg-white" />
              <a className="text-body-reg-14 font-normal text-white">
                Conscious Play
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
