import React, { useEffect, useState } from "react";
import PixupCasinoLogo from "../../assets/images/pixup-casino-logo.png";
import DropdownButton from "../core-components/button/DropdownButton";
import Icon from "../core-components/icon";
import CustomButton from "@components/core-components/button/CustomButton";
import { useAppSelector } from "hooks/redux";
import AuthModal from "@components/core-components/AuthModal";
import { useNavigate } from "react-router-dom";
import { baseService } from "api/core/baseService";
import { Wallet } from "./types";

function HeaderMobile() {
  const navigate = useNavigate();
  const [playerData, setPlayerData] = useState<any>([]);
  const [selectedWallet, setSelectedWallet] = useState<any>([]);
  const { isAuthenticated, loading } = useAppSelector((state) => state.auth);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [initialTab, setInitialTab] = useState<"signIn" | "register">(
    "register"
  );
  const openSignInModal = () => {
    setInitialTab("signIn");
    setIsAuthModalOpen(true);
  };

  const openRegisterModal = () => {
    setInitialTab("register");
    setIsAuthModalOpen(true);
  };

  useEffect(() => {
    userData();
    //if (isAuthenticated) {
    const intervalId = setInterval(() => {
      console.log("call");

      userData();
    }, 5000);

    return () => clearInterval(intervalId);
    //}
  }, []);

  const userData = async () => {
    try {
      const res = await baseService.getAll<Wallet[]>(
        "users/wallet/6734c8c7484ca261434ea4b4"
      );
      const wallets = res;

      setPlayerData(wallets);

      const activeWallet = wallets.find((wallet: any) => wallet.isActive);
      if (activeWallet) {
        setSelectedWallet(activeWallet);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateActiveWallet = async (userId: string, walletId: string) => {
    try {
      await baseService.update(`users/wallet/update/${userId}`, {
        walletId,
        playerId: "6734c8c7484ca261434ea4b4",
      });
      setSelectedWallet(
        playerData.find((wallet: any) => wallet._id === walletId)
      );
      userData(); // Refresh wallet data after updating
    } catch (error) {
      console.log(error);
    }
  };

  const closeAuthModal = () => setIsAuthModalOpen(false);

  return (
    <header className="w-full fixed top-0 z-20">
      <div className="bg-gray-900 h-[60px] flex items-center justify-between shadow-lg px-4">
        <button
          onClick={() => navigate(`/`)}
          className="flex flex-row w-52 h-6 items-end"
        >
          {isAuthenticated ? (
            <Icon
              iconName="pixupPink"
              svgProps={{ width: 34, height: 24 }}
              className="mr-1"
            />
          ) : (
            <Icon
              iconName="pixupYellow"
              svgProps={{ width: 34, height: 24 }}
              className="mr-1"
            />
          )}
          <Icon iconName="pixupClient" svgProps={{ width: 85, height: 15 }} />
          {isAuthenticated ? (
            <Icon iconName="pixupCasino" svgProps={{ width: 75, height: 12 }} />
          ) : null}
        </button>
        <div className="flex items-end gap-4">
          {isAuthenticated ? (
            <>
              <Icon
                iconName="profileIconWhite"
                svgProps={{ width: 20, height: 20 }}
                className="cursor-pointer"
              />
              <Icon
                iconName="notification"
                svgProps={{ width: 20, height: 20 }}
                className="cursor-pointer"
              />
            </>
          ) : (
            <div className="flex items-center gap-3">
              <CustomButton
                text="Sign In"
                onClick={openSignInModal}
                buttonType="secondary"
              />
              <CustomButton
                text="Register"
                onClick={openRegisterModal}
                buttonType="primary"
              />
              <AuthModal
                isOpen={isAuthModalOpen}
                onClose={closeAuthModal}
                initialTab={initialTab}
              />
            </div>
          )}
        </div>
      </div>

      {isAuthenticated && (
        <div
          style={{ marginBottom: 10 }}
          className="bg-gray-600 h-[56px] flex items-center justify-between pr-4"
        >
          <DropdownButton
            iconName="usdt"
            label={`${selectedWallet?.total} ${selectedWallet?.currency?.shortCut}`}
            iconWidth={24}
            iconHeight={24}
            options={[
              {
                label: "Amount:",
                icon: "tether",
                value: `${selectedWallet?.total} ${selectedWallet?.currency?.shortCut}`,
                onClick: () => console.log("My Bets"),
              },
              {
                label: "Withdrawable Amount:",
                icon: "withdrawableAmount",
                value: `${selectedWallet?.withdrawableAmount} ${selectedWallet?.currency?.shortCut}`,
                onClick: () => console.log("Settings"),
              },
              {
                label: "Bonus Dependent:",
                icon: "bonusDependent",
                value: `${selectedWallet?.bonusDependent} ${selectedWallet?.currency?.shortCut}`,
                onClick: () => console.log("Settings"),
              },
              {
                label: "Bonus:",
                icon: "bonusIcon",
                value: `${selectedWallet?.bonus} ${selectedWallet?.currency?.shortCut}`,
                onClick: () => console.log("Settings"),
              },
              /*     {
                    label: "Logout",
                    icon: "logout",
                    onClick: () => dispatch(logoutUser()),
                  }, */
            ]}
            footer={
              <div className="flex flex-row justify-center py-2 px-3 gap-3">
                <CustomButton
                  text="Withdraw"
                  onClick={() => console.log("Register")}
                  buttonType="secondary"
                />
                <CustomButton
                  text="Deposit"
                  onClick={() => console.log("Register")}
                  buttonType="primary"
                />
              </div>
            }
          />
          <div className="flex items-center">
            <CustomButton
              text="Deposit"
              onClick={() => console.log("Deposit")}
              buttonType="primary"
            />
          </div>
        </div>
      )}
    </header>
  );
}

export default HeaderMobile;
