import Modal from "@components/core-components/modal";
import React, { useState, useEffect } from "react";
import bank from "../../assets/images/bank.png";
import bitcoin from "../../assets/images/bitcoin.png";
import x from "../../assets/images/x.png";
import dash from "../../assets/images/dash.png";
import entropay from "../../assets/images/entropay.png";
import neosurf from "../../assets/images/neosurf.png";
import neteller from "../../assets/images/neteller.png";
import payeer from "../../assets/images/payeer.png";
import skrill from "../../assets/images/skrill.png";
import sticpay from "../../assets/images/sticpay.png";
import tron from "../../assets/images/tron.png";
import { Wallet } from "../layout-components/types";
import { baseService } from "api/core/baseService";
import WithdrawMethodButton from "./WithdrawMethodButton";
import WithdrawForm from "./WithdrawForm";
import Icon from "@components/core-components/icon";

interface WithdrawModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({ isOpen, onClose }) => {
  const [playerData, setPlayerData] = useState<any>([]);
  const [selectedWallet, setSelectedWallet] = useState<any>([]);
  const [selectedAmount, setSelectedAmount] = useState(500);
  const [walletAddress, setWalletAddress] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("bitcoin");
  const amounts = [500, 1000, 2500, 5000, 10000, 50000];

  useEffect(() => {
    userData();
  }, []);

  const userData = async () => {
    try {
      const res = await baseService.getAll<Wallet[]>(
        "users/wallet/6734c8c7484ca261434ea4b4"
      );
      const wallets = res;

      setPlayerData(wallets);

      const activeWallet = wallets.find((wallet: any) => wallet.isActive);
      if (activeWallet) {
        setSelectedWallet(activeWallet);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const iconDimensions: { [key: string]: { width: number; height: number } } = {
    bank: { width: 70, height: 30 },
    bitcoin: { width: 70, height: 30 },
    x: { width: 70, height: 30 },
    dash: { width: 70, height: 30 },
    tron: { width: 70, height: 30 },
    neosurf: { width: 70, height: 30 },
    payeer: { width: 70, height: 30 },
    neteller: { width: 70, height: 30 },
    sticpay: { width: 70, height: 30 },
    entropay: { width: 70, height: 30 },
    skrill: { width: 70, height: 30 },
  };

  const iconPaths: { [key: string]: string } = {
    bank,
    bitcoin,
    x,
    dash,
    tron,
    neosurf,
    payeer,
    neteller,
    sticpay,
    entropay,
    skrill,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className="flex flex-row gap-1 justify-center items-center w-full max-w-[980px]">
          <span className="text-lg font-semibold text-white">WITHDRAW</span>
        </div>
      }
      content={
        <div className="flex w-full max-w-[980px] h-auto rounded-lg p-6 pb-0 gap-12">
          <div className="flex flex-col gap-2">
            {[
              "bank",
              "bitcoin",
              "x",
              "dash",
              "tron",
              "neosurf",
              "payeer",
              "neteller",
              "sticpay",
              "entropay",
              "skrill",
            ].map((method, index) => (
              <WithdrawMethodButton
                key={index}
                method={method}
                selectedMethod={selectedMethod}
                onClick={setSelectedMethod}
                iconPath={iconPaths[method]}
                iconDimensions={iconDimensions[method]}
              />
            ))}
          </div>

          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center mb-4">
              <span className="text-base text-gray-100 font-semibold">
                Available Balance:
              </span>
              <div className="flex flex-row items-center gap-2">
                <Icon iconName="tether" svgProps={{ width: 20, height: 20 }} />
                <span className="text-base text-white font-semibold">
                  {selectedWallet?.total} {selectedWallet?.currency?.shortCut}
                </span>
              </div>
            </div>
            <hr className="border-t border-gray-400 mb-6" />
            <div className="text-white font-semibold text-lg mb-4">
              {selectedMethod.toUpperCase()}
            </div>
            <input
              type="text"
              placeholder="Wallet Address"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              className="w-full p-3 rounded-lg text-sm text-white placeholder-gray-200 mb-5"
            />
            <WithdrawForm
              title="Withdraw Amount"
              selectedAmount={selectedAmount}
              setSelectedAmount={setSelectedAmount}
              amounts={amounts}
            />
          </div>
        </div>
      }
      footer={<></>}
    />
  );
};

export default WithdrawModal;
