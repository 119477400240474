import React, { useEffect, useRef, useState } from "react";
import slotIcon from "../../../../assets/images/slot-icon.png";
import jackpotIcon from "../../../../assets/images/jackpot-icon.png";
import dropWinsIcon from "../../../../assets/images/drop-wins-icon.png";
import Carousel from "./Carousel";
import GameCard from "../../../../components/core-components/card/GameCard";
import TabsComponent from "../../../../components/core-components/TabsComponent";
import ProviderCard from "../../../../components/core-components/card/ProviderCard";
import PromotionCard from "../../../../components/core-components/card/PromotionCard";
import CasinoTable from "./CasinoTable";
import "swiper/css";
import { games, providers } from "../../../../data/data";
import Footer from "../../../../components/layout-components/Footer";
import CustomSwiper from "@components/core-components/swiper";
import SearchModal from "./SearchModal";
import SearchBar from "@components/core-components/input/SearchBar";
import { casinoGames, fetchGames } from "store/casino/casinoSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";

function Main() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const games = useAppSelector(casinoGames);
  const dispatch = useAppDispatch();
  const [isSearchModalVisible, setSearchModalVisible] =
    useState<boolean>(false);

  const handleOpenSearchModal = () =>
    setSearchModalVisible(!isSearchModalVisible);

  useEffect(() => {
    dispatch(fetchGames());
  }, []);
  const [activeTab, setActiveTab] = useState("lobby");
  const breakpoints = {
    "0": { slidesPerView: 3, spaceBetween: 0 }, // Mobile screens
    "820": { slidesPerView: 3.5, spaceBetween: 0 },
    "950": { slidesPerView: 2, spaceBetween: 0 },
    "1024": { slidesPerView: 2.5, spaceBetween: 0 },
    "1190": { slidesPerView: 3, spaceBetween: 0 },
    "1200": { slidesPerView: 3.5, spaceBetween: 0 },
    "1260": { slidesPerView: 4.3, spaceBetween: 0 },
    "1550": { slidesPerView: 6, spaceBetween: 0 },
    "1800": { slidesPerView: 7.5, spaceBetween: 0 },
    "2000": { slidesPerView: 9, spaceBetween: 0 },
  };
  const tabs = [
    { id: "lobby", label: "Lobby" },
    { id: "slots", label: "Slots" },
    { id: "liveCasino", label: "Live Casino" },
    { id: "gameShows", label: "Game Shows" },
    { id: "crashGames", label: "Crash Games" },
    { id: "newRelease", label: "New Release" },
  ];

  return (
    <main className="flex-grow w-full bg-gray-700 h-screen overflow-x-hidden main-no-scrollbar">
      <div className="flex-grow pb-72 p-0 md:p-8 w-full overflow-x-hidden">
        <Carousel />
        <SearchModal
          isOpen={isSearchModalVisible}
          onClose={() => setSearchModalVisible(false)}
        />
        <div className="flex flex-row h-auto pt-3 px-3 md:pt-8 md:px-0 gap-4">
          <TabsComponent
            tabs={tabs}
            isHomeScreen={true}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            classNames="border border-solid bg-[#0D1120] border-width border-[#2C3555]"
          />

          {!isMobile && (
            <SearchBar
              onClick={handleOpenSearchModal}
              classNames="md:max-w-[300px]"
              value=""
            />
          )}
        </div>
        <div className="pt-6 w-full px-3 md:px-0">
          <CustomSwiper
            title="Slots"
            items={games}
            breakpoints={breakpoints}
            renderItem={(game) => (
              <GameCard
                image={game.url_thumb}
                id={game.game_id}
                name={game.game_name}
                altText={game.game_name}
                notAvailable={game.status === "ACTIVE" ? false : true}
                showPlayCount={true}
              />
            )}
          />
        </div>
        <div className="pt-6 md:pt-10 w-full px-3 md:px-0">
          <CustomSwiper
            title="Live Casino"
            items={games.slice(7)}
            breakpoints={breakpoints}
            renderItem={(game) => (
              <GameCard
                image={game.image}
                id={game.id}
                name={game.name}
                altText={game.name}
                notAvailable={game.notAvailable}
                showPlayCount={true}
              />
            )}
          />
        </div>
        <div className="pt-6 md:pt-10 w-full px-3 md:px-0">
          <CustomSwiper
            title="Providers"
            items={providers.map((provider, index) => ({
              ...provider,
              id: index,
            }))}
            showViewAll={false}
            breakpoints={breakpoints}
            renderItem={(provider) => (
              <ProviderCard logo={provider.logo} name={provider.name} />
            )}
          />
        </div>
        <div className="py-9 md:py-16 w-full px-3 md:px-0">
          <div className="flex flex-wrap gap-4 md:gap-8 justify-center lg:justify-between items-center">
            <PromotionCard
              icon={slotIcon}
              title="MEGAWAYS"
              subtitle="All the Best Slots"
              iconWidth="w-[110px] md:w-[130px] lg:w-[195px]"
              iconHeight="h-[90px] md:h-[108px] lg:h-[162px]"
              customStyles="bg-gradient-to-r from-[#D02462] to-[#ED535A]"
              top="-top-5"
              left="left-0"
            />
            <PromotionCard
              icon={jackpotIcon}
              title="JACKPOTS"
              subtitle="Massive Jackpots"
              iconWidth="w-[110px] md:w-[130px] lg:w-[150px]"
              iconHeight="h-[90px] md:h-[108px] lg:h-[162px]"
              customStyles="bg-gradient-to-r from-[#223A83] to-[#5780F9]"
              top="-top-6"
              left="left-4"
            />
            <PromotionCard
              icon={dropWinsIcon}
              title="DROP & WINS"
              subtitle="Big Prize"
              iconWidth="w-[100px] md:w-[110px] lg:w-[131px]"
              iconHeight="h-[80px] md:h-[108px] lg:h-[131px]"
              customStyles="bg-[linear-gradient(260deg,#B85690_1.24%,#792ADF_100%)]"
              top="-top-4"
              left="left-4"
            />
          </div>
        </div>
        <div className="w-full px-3 md:px-0">
          <CustomSwiper
            title="Game Shows"
            items={games}
            breakpoints={breakpoints}
            renderItem={(game) => (
              <GameCard
                image={game.url_thumb}
                id={game.game_id}
                name={game.game_name}
                altText={game.game_name}
                notAvailable={game.status === "ACTIVE" ? false : true}
                showPlayCount={true}
              />
            )}
          />
        </div>
        <div className="pt-6 md:pt-10 w-full px-3 md:px-0">
          <CustomSwiper
            title="Crash Games"
            items={games.slice(7)}
            breakpoints={breakpoints}
            renderItem={(game) => (
              <GameCard
                image={game.image}
                id={game.id}
                name={game.name}
                altText={game.name}
                notAvailable={game.notAvailable}
                showPlayCount={true}
              />
            )}
          />
        </div>
        <div className="pt-6 md:pt-10 w-full px-3 md:px-0">
          <CustomSwiper
            title="New Release"
            items={games}
            breakpoints={breakpoints}
            renderItem={(game) => (
              <GameCard
                image={game.image}
                id={game.id}
                name={game.name}
                altText={game.name}
                notAvailable={game.notAvailable}
                showPlayCount={true}
              />
            )}
          />
        </div>
        <div className="pt-6 md:pt-10 w-full px-3 md:px-0">
          <div className="flex justify-between items-center text-white text-lg font-black mb-4">
            <span>Bets</span>
            <button className="text-sm">View All</button>
          </div>
          <CasinoTable />
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default Main;
