import React, { useEffect, useState } from "react";
import PixupCasinoLogo from "../../assets/images/pixup-casino-logo.png";
import DropdownButton from "../core-components/button/DropdownButton";
import Icon from "../core-components/icon";
import { useNavigate } from "react-router-dom";
import CustomButton from "@components/core-components/button/CustomButton";
import AuthModal from "@components/core-components/AuthModal";
import { useAppSelector } from "hooks/redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { logoutUser, setOpenModal } from "store/auth/authenticationSlice";
import { baseService } from "api/core/baseService";
import { Wallet } from "./types";
import WithdrawModal from "@components/money-components/WithdrawModal";
import DepositModal from "@components/money-components/DepositModal";

function Header() {
  const navigate = useNavigate();
  const [playerData, setPlayerData] = useState<any>([]);
  const [selectedWallet, setSelectedWallet] = useState<any>([]);
  const { isAuthenticated, loading } = useAppSelector((state) => state.auth);
  const dispatch: any = useDispatch();
  const openModal = useSelector((state: RootState) => state.auth.openModal);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

  useEffect(() => {
    //if (isAuthenticated) {
    const intervalId = setInterval(() => {
      userData();
    }, 5000);

    return () => clearInterval(intervalId);
    //  }
  }, []);

  const userData = async () => {
    try {
      const res = await baseService.getAll<Wallet[]>(
        "users/wallet/6734c8c7484ca261434ea4b4"
      );
      const wallets = res;
      setPlayerData(wallets);

      const activeWallet = wallets.find((wallet: any) => wallet.isActive);
      if (activeWallet) {
        setSelectedWallet(activeWallet);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateActiveWallet = async (userId: string, walletId: string) => {
    try {
      await baseService.update(`users/wallet/update/${userId}`, {
        walletId,
        playerId: "6734c8c7484ca261434ea4b4",
      });
      setSelectedWallet(
        playerData.find((wallet: any) => wallet._id === walletId)
      );
      userData(); // Refresh wallet data after updating
    } catch (error) {
      console.log(error);
    }
  };

  const [initialTab, setInitialTab] = useState<"signIn" | "register">(
    "register"
  );

  const openSignInModal = () => {
    setInitialTab("signIn");
    dispatch(setOpenModal(true));
  };

  const openRegisterModal = () => {
    setInitialTab("register");
    dispatch(setOpenModal(true));
  };
  useEffect(() => {
    isAuthenticated && userData();
  }, [isAuthenticated]);

  const closeAuthModal = () => dispatch(setOpenModal(false));
  const openWithdrawModal = () => setIsWithdrawModalOpen(true);
  const closeWithdrawModal = () => setIsWithdrawModalOpen(false);
  const openDepositModal = () => setIsDepositModalOpen(true);
  const closeDepositModal = () => setIsDepositModalOpen(false);

  return (
    <header className="z-20 bg-gray-900 h-[60px] w-full fixed top-0 flex items-center justify-between shadow-lg">
      {/* Logo */}
      {isAuthenticated ? (
        <button
          onClick={() => navigate(`/`)}
          className="w-[190px] h-full px-5 justify-center bg-gray-700"
        >
          <img
            src={PixupCasinoLogo}
            alt="logo"
            className="h-4 w-[152px] cursor-pointer"
          />
        </button>
      ) : (
        <button
          onClick={() => navigate(`/`)}
          className="flex flex-row w-52 pl-6 h-6 items-end"
        >
          <Icon
            iconName="pixupYellow"
            svgProps={{ width: 34, height: 24 }}
            className="mr-1"
          />
          <Icon iconName="pixupClient" svgProps={{ width: 85, height: 15 }} />
        </button>
      )}
      <div className="flex items-center h-full">
        {isAuthenticated && (
          <>
            {/* Money Dropdown */}
            <div
              style={{ marginBottom: 10 }}
              className="flex items-center h-full border-r border-l border-gray-300 duration-200 hover:bg-gray-400"
            >
              <DropdownButton
                iconName="usdt"
                label={`${selectedWallet?.total} ${selectedWallet?.currency?.shortCut}`}
                iconWidth={24}
                iconHeight={24}
                dropdownWidth="365px"
                options={[
                  {
                    label: "Amount:",
                    icon: "tether",
                    value: `${selectedWallet?.total} ${selectedWallet?.currency?.shortCut}`,
                    onClick: () => console.log("My Bets"),
                  },
                  {
                    label: "Withdrawable Amount:",
                    icon: "withdrawableAmount",
                    value: `${selectedWallet?.withdrawableAmount} ${selectedWallet?.currency?.shortCut}`,
                    onClick: () => console.log("Settings"),
                  },
                  {
                    label: "Bonus Dependent:",
                    icon: "bonusDependent",
                    value: `${selectedWallet?.bonusDependent} ${selectedWallet?.currency?.shortCut}`,
                    onClick: () => console.log("Settings"),
                  },
                  {
                    label: "Bonus:",
                    icon: "bonusIcon",
                    value: `${selectedWallet?.bonus} ${selectedWallet?.currency?.shortCut}`,
                    onClick: () => console.log("Settings"),
                  },
                  /*     {
                    label: "Logout",
                    icon: "logout",
                    onClick: () => dispatch(logoutUser()),
                  }, */
                ]}
                footer={
                  <div className="flex flex-row justify-center py-2 px-3 gap-3">
                    <CustomButton
                      text="Withdraw"
                      onClick={openWithdrawModal}
                      buttonType="secondary"
                    />
                    <CustomButton
                      text="Deposit"
                      onClick={openDepositModal}
                      buttonType="primary"
                    />
                  </div>
                }
              />
            </div>
            <div className="flex items-center h-full gap-6 px-6">
              <CustomButton
                text="Deposit"
                onClick={openDepositModal}
                buttonType="primary"
              />
            </div>
          </>
        )}
        <WithdrawModal
          isOpen={isWithdrawModalOpen}
          onClose={closeWithdrawModal}
        />
        <DepositModal isOpen={isDepositModalOpen} onClose={closeDepositModal} />
        {/* Search Input */}
        <div className="flex items-center gap-4 h-full w-[265px] border-l border-gray-300 pr-6 pl-4">
          <Icon
            iconName="searchWhite"
            svgProps={{
              width: 16,
              height: 16,
            }}
          />
          <span className="text-white text-sm font-black">Search</span>
          <input
            type="text"
            className="bg-transparent w-full text-sm font-medium text-gray-200 placeholder-gray-200 focus:outline-none"
          />
        </div>
        {/* Language Dropdown */}
        <div className="flex items-center h-full border-r border-l border-gray-300 duration-200 hover:bg-gray-400">
          <DropdownButton
            iconName="UK"
            label="EN"
            iconWidth={20}
            iconHeight={20}
            options={[
              { label: "English", onClick: () => console.log("English") },
              { label: "Français", onClick: () => console.log("Français") },
            ]}
          />
        </div>

        {isAuthenticated && (
          <>
            {/* Notification Button */}
            <button
              type="button"
              className="flex items-center justify-center h-full border-r border-gray-300 duration-200 hover:bg-gray-400 px-6 cursor-pointer"
              onClick={() => console.log("Notification")}
            >
              <Icon
                iconName="notification"
                svgProps={{
                  width: 14,
                  height: 17,
                }}
              />
            </button>
          </>
        )}

        {!isAuthenticated ? (
          <div className="flex items-center h-full gap-6 px-6">
            <CustomButton
              text="Sign In"
              onClick={openSignInModal}
              buttonType="secondary"
            />
            <CustomButton
              text="Register"
              onClick={openRegisterModal}
              buttonType="primary"
            />
            <AuthModal
              isOpen={openModal}
              onClose={closeAuthModal}
              initialTab={initialTab}
            />
          </div>
        ) : (
          <div className="flex items-center h-full border-r border-l border-gray-300 duration-200 hover:bg-gray-400">
            <DropdownButton
              iconName="profileIconWhite"
              label=""
              iconWidth={16}
              iconHeight={16}
              options={[
                {
                  label: "My Bets",
                  icon: "myBets",
                  onClick: () => console.log("My Bets"),
                },
                {
                  label: "Settings",
                  icon: "settings",
                  onClick: () => console.log("Settings"),
                },
                {
                  label: "Logout",
                  icon: "logout",
                  onClick: () => dispatch(logoutUser()),
                },
              ]}
            />
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
